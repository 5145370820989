import React from 'react';
import { Box, FormControl, InputLabel, Select } from '@material-ui/core';

import axios from 'axios';
import { useSetAtom } from 'jotai';

import CreateDialog from 'views/components/dialogs/ChecklistUpload/CreateDialog';
import { DataTable } from 'views/components/tables/ChecklistUpload/DataTable';
import { SelectControl } from 'views/molecules/SelectControl';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

import { CHECKLIST_KBN_OPT, CHECKLIST_NAME_OPT, NO_OPT } from 'utils/constants';
import { convertJST } from 'utils/functions';
import CommentDialog from 'views/components/dialogs/ChecklistUpload/CommentDialog';

export const ChecklistUploadContext = React.createContext(
  {} as {
    fiscal_year: number;
    pref_code: string;
    bank_code: string;
    department_level_code: number;
    dept_code_lv2: number;
    dept_name_lv2: string;
    dept_code_lv3: number;
    dept_name_lv3: string;
    dept_code_lv4: number;
    dept_name_lv4: string;
    dept_code_lv5: number;
    dept_name_lv5: string;
    user_name: string;
    data;
    fileRef;
    errorFile;
    setErrorFile;
    errorChecklistName;
    setErrorChecklistName;
    checkedInfo;
    setCheckedInfo;
    setData;
    setOrgData;
    mapReturnedComment;
    setMapReturnedComment;
    mapVerificationHist;
    setMapVerificationHist;
    inspectionMonth;
    selectedInspectionMonth;
    selectedCategory;
    selectedSubcategory;
    mapInspectionMonth2;
    mapPrio;
  }
);

export const ChecklistUpload: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const {
    pref_code,
    bank_code,
    department_level_code,
    dept_code_lv2,
    dept_name_lv2,
    dept_code_lv3,
    dept_name_lv3,
    dept_code_lv4,
    dept_name_lv4,
    dept_code_lv5,
    dept_name_lv5,
    user_name,
  } = user;

  const [inspectionMonth, setInspectionMonth] = React.useState([]);
  const [mapInspectionMonth, setMapInspectionMonth] = React.useState(new Map());
  const [selectedInspectionMonth, setSelectedInspectionMonth] =
    React.useState(1);

  const [category, setCategory] = React.useState(CHECKLIST_KBN_OPT);
  const [subcategory, setSubcategory] = React.useState(NO_OPT);

  const [data, setData] = React.useState([]);
  const [orgData, setOrgData] = React.useState([]);

  const fileRef = React.useRef<HTMLInputElement>(null);
  const [errorFile, setErrorFile] = React.useState('');
  const [errorChecklistName, setErrorChecklistName] = React.useState('');

  const [checkedInfo, setCheckedInfo] = React.useState({ key: -1 });

  const setOffset = useSetAtom(offsetAtom);

  const [fiscal_years, setFiscalYears] = React.useState([]);

  const [selectedFiscalYear, setSelectedFiscalYear] = React.useState(0);
  const [selectedCategory, setSelectedCategory] = React.useState(0);
  const [selectedSubcategory, setSelectedSubcategory] = React.useState(0);

  const [mapVerificationHist, setMapVerificationHist] = React.useState(
    new Map()
  );

  const [mapReturnedComment, setMapReturnedComment] = React.useState(new Map());

  const [mapInspectionMonth2, setMapInspectionMonth2] = React.useState(
    new Map()
  );

  const [initialMonth, setInitialMonth] = React.useState(0);

  const [mapPrio, setMapPrio] = React.useState(new Map());

  const handleChangeFiscalYear = (e) => {
    const year = Number(e.target.value);
    setSelectedFiscalYear(year);

    const targetData = orgData
      .filter((x) => {
        return (
          x.fiscal_year === year && parseInt(x.inspection_month) == initialMonth
        );
      })
      .filter((x) => {
        let tmpAry = [0, 0, 0, 0, 0];

        if (mapInspectionMonth2.get(year)) {
          tmpAry =
            mapInspectionMonth2.get(year)[parseInt(x.inspection_month) - 1];
        }

        const key = `${x.fiscal_year}:${x.pref_code}:${x.bank_code}:${x.category_code}:${x.subcategory_code}`;

        const excludePrio = [
          mapPrio.get(key) ? mapPrio.get(key)[0] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[1] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[2] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[3] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[4] : 0,
        ];

        if (
          (x.freq_year_count - excludePrio[0] > 0 && tmpAry[0]) ||
          (x.freq_half_count - excludePrio[1] > 0 && tmpAry[1]) ||
          (x.freq_quarter_count - excludePrio[2] > 0 && tmpAry[2]) ||
          (x.freq_bimonthly_count - excludePrio[3] > 0 && tmpAry[3]) ||
          (x.freq_month_count - excludePrio[4] > 0 && tmpAry[4])
        ) {
          return x;
        }
      });

    setData(targetData);

    {
      const tmpMap = new Map();

      targetData.map((x) => {
        tmpMap.set(x.category_code, x.category_name.substring(2));
      });

      const res = [];

      tmpMap.forEach((v, k) => {
        res.push({ key: k, val: v });
      });

      setCategory([...CHECKLIST_KBN_OPT, ...res]);
      setSelectedCategory(0);
    }

    setSubcategory(NO_OPT);
    setSelectedSubcategory(0);

    setInspectionMonth(mapInspectionMonth.get(year));
    setSelectedInspectionMonth(initialMonth);

    setOffset(0);
    setCheckedInfo({ key: -1 });
  };

  const handleChangeInspectionMonth = (e) => {
    const targetData = orgData
      .filter((x) => {
        return (
          x.fiscal_year === selectedFiscalYear &&
          x.inspection_month === e.target.value
        );
      })
      .filter((x) => {
        let tmpAry = [0, 0, 0, 0, 0];

        if (mapInspectionMonth2.get(selectedFiscalYear)) {
          tmpAry =
            mapInspectionMonth2.get(selectedFiscalYear)[
              parseInt(x.inspection_month) - 1
            ];
        }

        const key = `${x.fiscal_year}:${x.pref_code}:${x.bank_code}:${x.category_code}:${x.subcategory_code}`;

        const excludePrio = [
          mapPrio.get(key) ? mapPrio.get(key)[0] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[1] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[2] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[3] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[4] : 0,
        ];

        if (
          (x.freq_year_count - excludePrio[0] > 0 && tmpAry[0]) ||
          (x.freq_half_count - excludePrio[1] > 0 && tmpAry[1]) ||
          (x.freq_quarter_count - excludePrio[2] > 0 && tmpAry[2]) ||
          (x.freq_bimonthly_count - excludePrio[3] > 0 && tmpAry[3]) ||
          (x.freq_month_count - excludePrio[4] > 0 && tmpAry[4])
        ) {
          return x;
        }
      });

    setData(targetData);

    setSelectedInspectionMonth(e.target.value);
    setSelectedCategory(0);

    setSubcategory(NO_OPT);
    setSelectedSubcategory(0);

    setOffset(0);
    setCheckedInfo({ key: -1 });
  };

  const handleChangeC = (e) => {
    const keyCateg = Number(e.target.value);
    setSelectedCategory(keyCateg);

    const targetData = orgData
      .filter((x) => {
        return (
          x.fiscal_year === selectedFiscalYear &&
          (keyCateg === 0 ? x.category_code : x.category_code === keyCateg) &&
          parseInt(x.inspection_month) ===
            (Number(selectedInspectionMonth)
              ? Number(selectedInspectionMonth)
              : 1)
        );
      })
      .filter((x) => {
        let tmpAry = [0, 0, 0, 0, 0];

        if (mapInspectionMonth2.get(selectedFiscalYear)) {
          tmpAry =
            mapInspectionMonth2.get(selectedFiscalYear)[
              parseInt(x.inspection_month) - 1
            ];
        }

        const key = `${x.fiscal_year}:${x.pref_code}:${x.bank_code}:${x.category_code}:${x.subcategory_code}`;

        const excludePrio = [
          mapPrio.get(key) ? mapPrio.get(key)[0] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[1] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[2] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[3] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[4] : 0,
        ];

        if (
          (x.freq_year_count - excludePrio[0] > 0 && tmpAry[0]) ||
          (x.freq_half_count - excludePrio[1] > 0 && tmpAry[1]) ||
          (x.freq_quarter_count - excludePrio[2] > 0 && tmpAry[2]) ||
          (x.freq_bimonthly_count - excludePrio[3] > 0 && tmpAry[3]) ||
          (x.freq_month_count - excludePrio[4] > 0 && tmpAry[4])
        ) {
          return x;
        }
      });

    setData(targetData);

    {
      const tmpMap = new Map();

      targetData.map((x) => {
        tmpMap.set(x.subcategory_code, {
          key: x.subcategory_code,
          val: x.subcategory_name.substring(2),
        });
      });

      if (keyCateg === 0) {
        setSubcategory(NO_OPT);
      } else {
        setSubcategory([...CHECKLIST_NAME_OPT, ...Array.from(tmpMap.values())]);
      }

      setSelectedSubcategory(0);
    }

    setOffset(0);
    setCheckedInfo({ key: -1 });
  };

  const handleChangeS = (e) => {
    const keySubCateg = Number(e.target.value);
    setSelectedSubcategory(keySubCateg);

    const targetData = orgData
      .filter((x) => {
        return (
          x.fiscal_year === selectedFiscalYear &&
          (selectedCategory === 0
            ? x.category_code
            : x.category_code === selectedCategory) &&
          (keySubCateg === 0
            ? x.subcategory_code
            : x.subcategory_code === keySubCateg) &&
          parseInt(x.inspection_month) ===
            (Number(selectedInspectionMonth)
              ? Number(selectedInspectionMonth)
              : 1)
        );
      })
      .filter((x) => {
        let tmpAry = [0, 0, 0, 0, 0];

        if (mapInspectionMonth2.get(selectedFiscalYear)) {
          tmpAry =
            mapInspectionMonth2.get(selectedFiscalYear)[
              parseInt(x.inspection_month) - 1
            ];
        }

        const key = `${x.fiscal_year}:${x.pref_code}:${x.bank_code}:${x.category_code}:${x.subcategory_code}`;

        const excludePrio = [
          mapPrio.get(key) ? mapPrio.get(key)[0] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[1] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[2] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[3] : 0,
          mapPrio.get(key) ? mapPrio.get(key)[4] : 0,
        ];

        if (
          (x.freq_year_count - excludePrio[0] > 0 && tmpAry[0]) ||
          (x.freq_half_count - excludePrio[1] > 0 && tmpAry[1]) ||
          (x.freq_quarter_count - excludePrio[2] > 0 && tmpAry[2]) ||
          (x.freq_bimonthly_count - excludePrio[3] > 0 && tmpAry[3]) ||
          (x.freq_month_count - excludePrio[4] > 0 && tmpAry[4])
        ) {
          return x;
        }
      });

    setData(targetData);

    setOffset(0);
    setCheckedInfo({ key: -1 });
  };

  React.useEffect(() => {
    Promise.all([
      axios.get('/api/fiscal-year-option-temp', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/checklist-upload/checklist-result', {
        params: {
          pref_code,
          bank_code,
          department_level_code,
          dept_code_lv2,
          dept_code_lv3,
          dept_code_lv4,
          dept_code_lv5,
        },
      }),

      axios.get('/api/fiscal-year-start-month'),

      axios.get('/api/checklist-confirm/verification-history', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/ja-master/start-month', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/inspection-month', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/checkitem_priority', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      axios.get('/api/current-inspection-month'),
    ]).then((res) => {
      const tmp = res[0].data;
      const curr_year = res[0].data[0].fiscal_year;
      const prev_year = curr_year - 1;

      const fySmMap = new Map();

      Object.entries(res[2].data).forEach((x) => {
        fySmMap.set(x[0], x[1]);
      });

      const cKey = `${curr_year}:${bank_code}`;
      const pKey = `${prev_year}:${bank_code}`;

      if (tmp.length === 1) {
        setFiscalYears([
          { key: curr_year, val: `${curr_year} 年度${fySmMap.get(cKey)}` },
        ]);
      } else {
        setFiscalYears([
          { key: curr_year, val: `${curr_year} 年度${fySmMap.get(cKey)}` },
          { key: prev_year, val: `${prev_year} 年度${fySmMap.get(pKey)}` },
        ]);
      }

      setSelectedFiscalYear(curr_year);

      const tmpMap2 = new Map();
      {
        res[5].data.forEach((x) => {
          const tmpAry = [];

          for (let i = 1; i <= 12; i++) {
            tmpAry.push(
              x['month_' + `0${i}`.slice(-2)].split('').map((x) => {
                return x === '1' ? true : false;
              })
            );
          }

          tmpMap2.set(x.fiscal_year, tmpAry);

          setMapInspectionMonth2(tmpMap2);
        });
      }

      const tmpPrioMap = new Map();

      res[6].data[0].forEach((x) => {
        const key = `${x.fiscal_year}:${x.pref_code}:${x.bank_code}:${x.category_code}:${x.subcategory_code}`;

        const exclude = [0, 0, 0, 0, 0];

        x.mapping_info.split(',').forEach((x) => {
          if (x.includes('×')) {
            exclude[parseInt(x.slice(-1)) - 1] += 1;
          }
        });

        tmpPrioMap.set(key, exclude);
      });

      setMapPrio(tmpPrioMap);

      const tmpMapCurrMonth = new Map();

      res[7].data.forEach((x) => {
        const key = x.split('#')[0];
        const val = x.split('#')[1];
        tmpMapCurrMonth.set(key, val);
      });

      const key = `${curr_year}:${pref_code}:${bank_code}`;
      setInitialMonth(tmpMapCurrMonth.get(key));
      setSelectedInspectionMonth(tmpMapCurrMonth.get(key));

      const tmp4 = res[1].data
        .filter((x) => {
          return (
            x.fiscal_year === curr_year &&
            parseInt(x.inspection_month) == tmpMapCurrMonth.get(key)
          );
        })
        .filter((x) => {
          const key = `${x.fiscal_year}:${x.pref_code}:${x.bank_code}:${x.category_code}:${x.subcategory_code}`;

          let tmpAry = [0, 0, 0, 0, 0];

          if (tmpMap2.get(curr_year)) {
            tmpAry = tmpMap2.get(curr_year)[parseInt(x.inspection_month) - 1];
          }

          const excludePrio = [
            tmpPrioMap.get(key) ? tmpPrioMap.get(key)[0] : 0,
            tmpPrioMap.get(key) ? tmpPrioMap.get(key)[1] : 0,
            tmpPrioMap.get(key) ? tmpPrioMap.get(key)[2] : 0,
            tmpPrioMap.get(key) ? tmpPrioMap.get(key)[3] : 0,
            tmpPrioMap.get(key) ? tmpPrioMap.get(key)[4] : 0,
          ];

          if (
            (x.freq_year_count - excludePrio[0] > 0 && tmpAry[0]) ||
            (x.freq_half_count - excludePrio[1] > 0 && tmpAry[1]) ||
            (x.freq_quarter_count - excludePrio[2] > 0 && tmpAry[2]) ||
            (x.freq_bimonthly_count - excludePrio[3] > 0 && tmpAry[3]) ||
            (x.freq_month_count - excludePrio[4] > 0 && tmpAry[4])
          ) {
            return x;
          }
        });

      setData(tmp4);
      setOrgData(res[1].data);

      setCategory([
        ...CHECKLIST_KBN_OPT,
        ...tmp4
          .filter(
            (elm, idx, self) =>
              self.findIndex((x) => x.category_code === elm.category_code) ===
              idx
          )
          .map((x) => {
            return {
              key: x.category_code,
              val: x.category_name.substring(2),
            };
          }),
      ]);

      res[3].data.hist
        .filter((x) => {
          return !x.is_deleted;
        })
        .filter((x) => {
          return (
            x.department_level_code === user.department_level_code &&
            (x.dept_code_lv1 || 0) === (user.dept_code_lv1 || 0) &&
            (x.dept_code_lv2 || 0) === (user.dept_code_lv2 || 0) &&
            (x.dept_code_lv3 || 0) === (user.dept_code_lv3 || 0) &&
            (x.dept_code_lv4 || 0) === (user.dept_code_lv4 || 0) &&
            (x.dept_code_lv5 || 0) === (user.dept_code_lv5 || 0)
          );
        })
        .forEach((x) => {
          const key = [
            x.fiscal_year,
            x.pref_code,
            x.bank_code,
            x.inspection_month,
            x.category_code,
            x.subcategory_code,
          ].join(':');

          if (x.returned_reason) {
            const val = [
              x.verified_user_name,
              convertJST(x.updatedAt),
              x.returned_reason,
            ].join('#');

            if (mapReturnedComment.has(key)) {
              const tmp = mapReturnedComment.get(key);
              tmp.push(val);
              mapReturnedComment.set(key, tmp);
            } else {
              mapReturnedComment.set(key, [val]);
            }
          }

          if (!x.is_approved) {
            mapVerificationHist.set(key, convertJST(x.updatedAt));
          }
        });

      setMapReturnedComment(new Map(mapReturnedComment));
      setMapVerificationHist(new Map(mapVerificationHist));

      const tmpMap = new Map();
      res[4].data.forEach((x) => {
        const tmp = [];

        for (let i = 1; i <= 12; i++) {
          const month = x.start_month + i - 1;

          tmp.push({
            key: i,
            val: month > 12 ? `${month - 12}月` : `${month}月`,
          });
        }

        tmpMap.set(x.fiscal_year, tmp);
      });

      setMapInspectionMonth(new Map(tmpMap));
      setInspectionMonth(tmpMap.get(curr_year));
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            チェックリストの出力・登録
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <FormControl variant='outlined' style={{ width: '320px' }}>
              <InputLabel>検査年度を選択</InputLabel>
              <Select
                native
                id='fiscal_year'
                value={selectedFiscalYear}
                onChange={handleChangeFiscalYear}
                label='検査年度を選択'
              >
                {fiscal_years.map((x) => (
                  <option key={x.key} value={x.key}>
                    {x.val}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <SelectControl
              label='検査対象月を選択'
              style={{ width: '200px', marginRight: 8 }}
              value={selectedInspectionMonth || 1}
              options={inspectionMonth}
              onChange={handleChangeInspectionMonth}
            />

            <SelectControl
              label='チェックリスト区分を選択'
              style={{ width: '200px', marginRight: 8 }}
              value={selectedCategory}
              options={category}
              onChange={handleChangeC}
            />

            <SelectControl
              label='チェックリストを選択'
              style={{ width: '200px', marginRight: 8 }}
              value={selectedSubcategory}
              options={subcategory}
              onChange={handleChangeS}
            />
          </div>

          <ChecklistUploadContext.Provider
            value={{
              fiscal_year: selectedFiscalYear,
              pref_code,
              bank_code,
              department_level_code,
              dept_code_lv2,
              dept_name_lv2,
              dept_code_lv3,
              dept_name_lv3,
              dept_code_lv4,
              dept_name_lv4,
              dept_code_lv5,
              dept_name_lv5,
              user_name,
              data,
              fileRef,
              errorFile,
              setErrorFile,
              errorChecklistName,
              setErrorChecklistName,
              checkedInfo,
              setCheckedInfo,
              setData,
              setOrgData,
              mapReturnedComment,
              setMapReturnedComment,
              mapVerificationHist,
              setMapVerificationHist,
              inspectionMonth,
              selectedInspectionMonth,
              selectedCategory,
              selectedSubcategory,
              mapInspectionMonth2,
              mapPrio,
            }}
          >
            <Box
              style={{ display: 'flex' }}
              alignItems='center'
              justifyContent='center'
              mt={3}
              mb={3}
            >
              <CreateDialog isDisabled={true} />
              <CommentDialog isDisabled={true} />
            </Box>

            <DataTable />
          </ChecklistUploadContext.Provider>
        </main>
      }
    />
  );
};

export default ChecklistUpload;
