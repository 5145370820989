import React from 'react';

import axios from 'axios';

import { Box, Dialog, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ChecklistVerificationContext } from 'views/pages/ChecklistVerification';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{
  isDialogOpen;
  setIsDialogOpen;
  yearOption;
}> = (props) => {
  const classes = useStyles();

  const {
    dispData,
    deleteInfo,
    dispInfo,
    mapVerification,
    setMapVerification,
    selectedDelId,
    setSelectedDelId,
    selectedKey,
  } = React.useContext(ChecklistVerificationContext);

  const { isDialogOpen, setIsDialogOpen } = props;

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (id) => {
    if (selectedDelId.includes(id)) {
      setSelectedDelId(selectedDelId.filter((x) => x !== id));
    } else {
      setSelectedDelId([...selectedDelId, id]);
    }
  };

  const handleDelete = () => {
    axios
      .post('/api/checklist-verification/delete', {
        id: selectedDelId,
      })
      .then(() => {
        const tmpMap = new Map();

        const tmp = mapVerification.get(selectedKey).filter((x) => {
          return !selectedDelId.includes(x.id.toString());
        });

        for (const [key, val] of mapVerification) {
          if (key === selectedKey) {
            if (tmp.length) {
              tmpMap.set(key, tmp);
            }
          } else {
            tmpMap.set(key, val);
          }
        }

        setMapVerification(tmpMap);
      })
      .catch(() => {
        //
      });

    handleClose();
  };

  return (
    <Box component='span' style={{ marginLeft: 8 }}>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName='検証結果取消'
          onClickActionHandler={handleClose}
        />
        <Divider />

        <div style={{ marginTop: 32, marginLeft: 32, marginBottom: 32 }}>
          <div style={{ fontSize: 16, margin: 0 }}>
            検査年度：
            {deleteInfo.inspectionYear}
          </div>
          <div style={{ fontSize: 16, marginTop: 8 }}>
            検査部署：{dispData[0]?.dept_name_lv2}&ensp;
            {dispData[0]?.dept_name_lv3}&ensp;
            {dispData[0]?.dept_name_lv4}&ensp;
            {dispData[0]?.dept_name_lv5}&ensp;
          </div>
          <div style={{ fontSize: 16, marginTop: 8 }}>
            検査対象月：{deleteInfo.inspectionMonth}
          </div>
          <div style={{ fontSize: 16, marginTop: 8 }}>
            チェックリスト名： {deleteInfo.chklstName}
          </div>

          <div style={{ marginTop: 8 }}>
            {dispInfo.map((x) => {
              return (
                <div key={x} style={{ display: 'flex' }}>
                  <div style={{ fontSize: 16, marginTop: 1.6, marginLeft: 16 }}>
                    <input
                      type='checkbox'
                      className='target'
                      color='default'
                      checked={selectedDelId.includes(x.split('#$%&%$#')[0])}
                      onChange={() => {
                        handleChange(x.split('#$%&%$#')[0]);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      marginTop: 0,
                      width: '9.0rem',
                      marginLeft: 16,
                    }}
                  >
                    {x.split('#$%&%$#')[1]}
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      marginTop: 0,
                      width: '10.0rem',
                      marginLeft: 16,
                    }}
                  >
                    {x.split('#$%&%$#')[2]}
                  </div>
                  {x.split('#$%&%$#')[3] ? (
                    <div
                      style={{
                        fontSize: 16,
                        marginTop: 0,
                        marginLeft: 32,
                        color: 'red',
                      }}
                    >
                      {'差戻済'}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: 16,
                        marginTop: 0,
                        marginLeft: 32,
                      }}
                    >
                      {'承認済'}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <Divider />

        <DialogActionsDelete
          buttonLabel='検証結果を取消する'
          onClickActionHandler={handleDelete}
          disabled={selectedDelId.length === 0}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
