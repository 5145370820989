import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE, USER_TYPE } from 'utils/constants';
import { UserContext } from 'views/pages/User';
import { UserForm } from 'views/components/forms/UserForm';
import { DialogButtonUpdate } from 'views/atoms/buttons/dialogs/Update';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const UpdateDialog: React.FC<{ checkedInfo; isDisabled: boolean }> = (
  props
) => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    selectedDeptLv,
    setDatas,
    setDeptLv1,
    setDeptLv2,
    setDeptLv3,
    setDeptLv4,
    setDeptLv5,
    setErrorDeptLvs,
    setErrorDeptLv1,
    setErrorDeptLv2,
    setErrorDeptLv3,
    setErrorDeptLv4,
    setErrorDeptLv5,
    setErrorUserName,
    setErrorUserPost,
    setErrorTelephoneNumber,
    setErrorEmail,
    setErrorPassword,
    setErrorDummySideTel,
    setErrorDummySideEmail,
    setErrorDummySidePass,
  } = React.useContext(UserContext);

  const {
    dept_code_lv1,
    dept_name_lv1,
    dept_code_lv2,
    dept_name_lv2,
    dept_code_lv3,
    dept_name_lv3,
    dept_code_lv4,
    dept_name_lv4,
    dept_code_lv5,
    dept_name_lv5,
    email,
  } = props.checkedInfo;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [release_flg, setReleaseFlg] = useState(false);

  const [orgEmail, setOrgEmail] = useState('');

  const handleOpen = () => {
    setIsDialogOpen(true);

    setOrgEmail(email);

    setDeptLv1([
      {
        key: dept_code_lv1,
        val: dept_name_lv1,
      },
    ]);

    setDeptLv2([
      {
        key: dept_code_lv2,
        val: dept_name_lv2,
      },
    ]);

    setDeptLv3([
      {
        key: dept_code_lv3,
        val: dept_name_lv3,
      },
    ]);

    setDeptLv4([
      {
        key: dept_code_lv4,
        val: dept_name_lv4,
      },
    ]);

    setDeptLv5([
      {
        key: dept_code_lv5,
        val: dept_name_lv5,
      },
    ]);
  };

  const handleClose = () => {
    setIsDialogOpen(false);

    setErrorDeptLvs('');
    setErrorDeptLv1('');
    setErrorDeptLv2('');
    setErrorDeptLv3('');
    setErrorDeptLv4('');
    setErrorDeptLv5('');

    setErrorUserName('');
    setErrorUserPost('');
    setErrorTelephoneNumber('');
    setErrorEmail('');
    setErrorPassword('');

    setErrorDummySideTel('');
    setErrorDummySideEmail('');
    setErrorDummySidePass('');
  };

  const handleUpdate = () => {
    const user_name =
      document.querySelector<HTMLInputElement>('#user-name').value;
    const user_post =
      document.querySelector<HTMLInputElement>('#user-post').value;
    const telephone_number =
      document.querySelector<HTMLInputElement>('#telephone-number').value;
    const email = document.querySelector<HTMLInputElement>('#email').value;
    const password =
      document.querySelector<HTMLInputElement>('#password')?.value;
    const account_lock =
      document.querySelector<HTMLInputElement>('#release-lock')?.value;

    axios
      .post('/api/user/update', {
        pref_code,
        bank_code,
        department_level_code: selectedDeptLv,
        dept_code_lv1,
        dept_code_lv2,
        dept_code_lv3,
        dept_code_lv4,
        dept_code_lv5,
        user_name,
        user_post,
        telephone_number,
        email,
        orgEmail,
        password,
        user_type: USER_TYPE.USER,
        release_flg,
      })
      .then((res) => {
        setDatas(res.data);

        props.checkedInfo.user_name = user_name;
        props.checkedInfo.user_post = user_post;
        props.checkedInfo.telephone_number = telephone_number;
        props.checkedInfo.email = email;
        props.checkedInfo.account_lock = account_lock;

        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.user_name
          ? setErrorUserName(errors.user_name.errorMsg)
          : setErrorUserName('');

        errors.user_post
          ? setErrorUserPost(errors.user_post.errorMsg)
          : setErrorUserPost('');

        errors.telephone_number
          ? setErrorTelephoneNumber(errors.telephone_number.errorMsg)
          : setErrorTelephoneNumber('');

        errors.email ? setErrorEmail(errors.email.errorMsg) : setErrorEmail('');

        errors.password
          ? setErrorPassword(errors.password.errorMsg)
          : setErrorPassword('');

        if (errors.user_name) {
          setErrorDeptLvs('　');
        } else {
          setErrorDeptLvs('');
        }

        if (errors.user_post) {
          setErrorDeptLv1('　');
          setErrorDeptLv2('　');
        } else {
          setErrorDeptLv2('');
          setErrorDeptLv1('');
        }

        if (errors.telephone_number) {
          setErrorDeptLv3('　');
          setErrorDummySideTel('　');
        } else {
          setErrorDeptLv3('');
          setErrorDummySideTel('');
        }

        if (errors.email) {
          setErrorDeptLv4('　');
          setErrorDummySideEmail('　');
        } else {
          setErrorDeptLv4('');
          setErrorDummySideEmail('');
        }

        if (errors.password) {
          setErrorDeptLv5('　');
          setErrorDummySidePass('　');
        } else {
          setErrorDeptLv5('');
          setErrorDummySidePass('');
        }
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonUpdate
        buttonLabel='ユーザ変更'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleUpdate
          titleName='ユーザ変更'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent
          style={{ height: '100%', marginTop: 12, marginBottom: 6 }}
        >
          <UserForm
            isDisabled={{
              dept_lv1: true,
              dept_lv2: true,
              dept_lv3: true,
              dept_lv4: true,
              dept_lv5: true,
              userName: false,
              userPost: false,
              telephoneNumber: false,
              email: false,
              password: false,
            }}
            defaultValues={{
              userName: props.checkedInfo.user_name,
              userPost: props.checkedInfo.user_post,
              telephoneNumber: props.checkedInfo.telephone_number,
              email: props.checkedInfo.email,
              account_lock: props.checkedInfo.account_lock,
            }}
            procType={PROC_TYPE.UPDATE}
            setReleaseFlg={setReleaseFlg}
          />
        </DialogContent>

        <Divider />

        <DialogActionsUpdate
          buttonLabel='ユーザを変更する'
          onClickActionHandler={handleUpdate}
        />
      </Dialog>
    </Box>
  );
};

export default UpdateDialog;
