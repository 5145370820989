import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { USER_TYPE } from 'utils/constants';

const useStyles = makeStyles(() =>
  createStyles({
    menu: {
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
        background: 'none',
      },
    },
  })
);

const Checklist: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const { user_type, department_level_code } = user;

  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {user_type === USER_TYPE.SYSTEM_ADMIN && (
        <ListItemText style={{ borderRight: '1px solid gray' }}>
          <Button
            aria-controls='simple-menu'
            aria-haspopup='true'
            className={classes.menu}
            onClick={handleClick}
          >
            チェックリスト管理
          </Button>

          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/inspection-category');
                }}
              >
                チェックリスト区分登録
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/inspection-subcategory');
                }}
              >
                チェックリスト登録
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/hidden-column-master');
                }}
              >
                非表示列設定
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-download');
                }}
              >
                チェックリストの出力
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-confirm');
                }}
              >
                検査結果の確認
              </span>
            </MenuItem>
          </Menu>
        </ListItemText>
      )}

      {user_type === USER_TYPE.ALL_PREF_ADMIN && (
        <ListItemText style={{ borderRight: '1px solid gray' }}>
          <Button
            aria-controls='simple-menu'
            aria-haspopup='true'
            className={classes.menu}
            onClick={handleClick}
          >
            チェックリスト管理
          </Button>

          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-download');
                }}
              >
                チェックリストの出力
              </span>
            </MenuItem>
          </Menu>
        </ListItemText>
      )}

      {user_type === USER_TYPE.PREF_ADMIN && (
        <ListItemText style={{ borderRight: '1px solid gray' }}>
          <Button
            aria-controls='simple-menu'
            aria-haspopup='true'
            className={classes.menu}
            onClick={handleClick}
          >
            チェックリスト管理
          </Button>

          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/inspection-subcategory');
                }}
              >
                チェックリスト変更・登録・承認
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/hidden-column-master');
                }}
              >
                非表示列設定
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-download');
                }}
              >
                チェックリストの出力
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-confirm');
                }}
              >
                検査結果の確認
              </span>
            </MenuItem>
          </Menu>
        </ListItemText>
      )}

      {user_type === USER_TYPE.JA_ADMIN && (
        <ListItemText style={{ borderRight: '1px solid gray' }}>
          <Button
            aria-controls='simple-menu'
            aria-haspopup='true'
            className={classes.menu}
            onClick={handleClick}
          >
            チェックリスト管理
          </Button>

          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/inspection-subcategory');
                }}
              >
                チェックリスト変更・登録
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/hidden-column-master');
                }}
              >
                非表示列設定
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-control');
                }}
              >
                部署別チェックリスト登録
              </span>
            </MenuItem>
            <Divider />

            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-confirm');
                }}
              >
                検査結果の確認・削除
              </span>
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-verification');
                }}
              >
                検証結果の取消
              </span>
            </MenuItem>
          </Menu>
        </ListItemText>
      )}

      {user_type === USER_TYPE.USER && department_level_code < 3 && (
        <ListItemText style={{ borderRight: '1px solid gray' }}>
          <Button
            aria-controls='simple-menu'
            aria-haspopup='true'
            className={classes.menu}
            onClick={handleClick}
          >
            自主検査の実施
          </Button>

          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-confirm');
                }}
              >
                検査結果の確認
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-verification');
                }}
              >
                検査結果の検証
              </span>
            </MenuItem>
          </Menu>
        </ListItemText>
      )}

      {user_type === USER_TYPE.USER && department_level_code >= 3 && (
        <ListItemText style={{ borderRight: '1px solid gray' }}>
          <Button
            aria-controls='simple-menu'
            aria-haspopup='true'
            className={classes.menu}
            onClick={handleClick}
          >
            自主検査の実施
          </Button>

          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-upload');
                }}
              >
                チェックリストの出力・登録
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-temp');
                }}
              >
                チェックリスト一時保存
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-confirm');
                }}
              >
                検査結果の確認
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <span
                style={{ width: '100%', padding: 5 }}
                onClick={() => {
                  history.replace('/checklist-verification');
                }}
              >
                検査結果の検証
              </span>
            </MenuItem>
          </Menu>
        </ListItemText>
      )}
    </>
  );
};

export default Checklist;
