import React, { useContext } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { useAtom } from 'jotai';

import { convertJST } from 'utils/functions';
import { PAR_PAGE } from 'utils/constants';
import { InspectionCategoryContext } from 'views/pages/InspectionCategory';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC<{
  datas;
  checkedInfo;
  setCheckedInfo;
}> = (props) => {
  const classes = useStyles();

  const parPage = PAR_PAGE;

  const { currFiscalYear, currFiscalYearPage } = useContext(
    InspectionCategoryContext
  );

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    setOffset(offset);
  };

  const handleCheckbox = (id: number) => {
    if (props.checkedInfo.includes(id)) {
      props.setCheckedInfo([]);
    } else {
      props.setCheckedInfo([id]);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow>
              <StyledTableCell align='center'>選択</StyledTableCell>
              <StyledTableCell>チェックリスト区分</StyledTableCell>
              <StyledTableCell>更新日時</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.datas?.slice(offset, offset + parPage).map((elm) => (
              <StyledTableRow key={elm.id}>
                <StyledTableCell align='center'>
                  <input
                    type='checkbox'
                    checked={props.checkedInfo.includes(elm.id)}
                    className='target'
                    color='default'
                    style={{ width: 12, height: 12, paddingLeft: 28 }}
                    onChange={() => {
                      handleCheckbox(elm.id);
                    }}
                    disabled={currFiscalYear != currFiscalYearPage}
                  />
                </StyledTableCell>
                <StyledTableCell>{elm.category_name}</StyledTableCell>
                <StyledTableCell>{convertJST(elm.updatedAt)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props.datas.length > PAR_PAGE && (
        <JICSPagination
          length={props.datas.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default DataTable;
