import React, { useState } from 'react';

import axios from 'axios';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { InspectionSubCategoryContext } from 'views/pages/InspectionSubcategory';
import InputTextField from 'views/molecules/InputTextField';
import SelectBoxField from 'views/molecules/SelectBoxField';
import InputUploadField from 'views/molecules/InputUploadField';

import { useAtomValue } from 'jotai';
import {
  errorFileNameAtom,
  errorChecklistNameAtom,
  errorFileUuidAtom,
} from 'jotai/atoms';
import { downloadExcel } from 'utils/functions';

const useStyles = makeStyles({
  inputField: {
    marginTop: 12,
  },
  selectBox: {
    marginTop: 12,
    width: '100%',
  },
  downloadable: {
    color: '#0033CC',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
});

type Props = {
  isDisabled: {
    category?: boolean;
    subcategoryName?: boolean;
    comment?: boolean;
  };
  isHidden?: {
    checklist: boolean;
    checkbox: boolean;
  };
  procType: number;
};

export const InspectionSubCategoryForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const {
    defaultValues,
    errorMsgs,
    fiscalYearOption,
    selectedFiscalYear,
    setSelectedFiscalYear,
    regOrgCategory,
    regCategory,
    setRegCategory,
  } = React.useContext(InspectionSubCategoryContext);

  const { isDisabled, isHidden } = props;

  const [checkboxToggle, setCheckboxToggle] = useState(false);

  const errorFileName = useAtomValue(errorFileNameAtom);
  const errorChecklistName = useAtomValue(errorChecklistNameAtom);
  const errorFileUuid = useAtomValue(errorFileUuidAtom);

  const handleChangeFiscalYear = (e) => {
    const currrFiscalYear = parseInt(e.target.value);

    setSelectedFiscalYear(currrFiscalYear);

    setRegCategory(
      regOrgCategory
        .filter((x) => {
          return x.fiscal_year == currrFiscalYear;
        })
        .map((x) => {
          return {
            key: x.category_code,
            val: x.category_name.substring(2),
          };
        })
    );
  };

  const handleClickCheckbox = (e) => {
    setCheckboxToggle(e.target.checked);
  };

  const handleDownload = async () => {
    const categoryCode = document.querySelector<HTMLSelectElement>(
      '#inspection-category'
    );

    const res = await axios.post(
      '/api/checklist-upload/error',
      {
        uuid: errorFileUuid,
        inspectionMonthVal: '',
        category_name: categoryCode.options[categoryCode.selectedIndex].text,
        subcategory_name: document.querySelector<HTMLInputElement>(
          '#inspection-subcategory-name'
        ).value,
      },
      {
        responseType: 'blob',
      }
    );

    downloadExcel(res);
  };

  return (
    <>
      <SelectBoxField
        id='fiscal-year'
        label='検査年度'
        className={classes.selectBox}
        defaultValue={selectedFiscalYear}
        options={fiscalYearOption}
        onChange={handleChangeFiscalYear}
        isDisabled={true}
      />
      <SelectBoxField
        id='inspection-category'
        label='チェックリスト区分を選択'
        className={classes.inputField}
        defaultValue={''}
        errorMsg={''}
        options={regCategory}
        isDisabled={isDisabled.category}
      />
      <InputTextField
        id='inspection-subcategory-name'
        label='チェックリスト名'
        className={classes.inputField}
        defaultValue={defaultValues.subcategoryName}
        errorMsg={errorMsgs.subcategoryName}
        isDisabled={isDisabled.subcategoryName}
      />
      <InputTextField
        id='comment'
        label='コメント'
        className={classes.inputField}
        defaultValue={defaultValues.comment}
        errorMsg={errorMsgs.comment}
        isDisabled={isDisabled.comment}
      />
      {!isHidden.checkbox && (
        <FormControlLabel
          control={<Checkbox color='primary' id='reupload' />}
          label='チェックリストを変更する'
          onClick={handleClickCheckbox}
        />
      )}
      <div style={{ display: isHidden.checklist ? 'none' : '' }}>
        {(checkboxToggle || isHidden.checkbox) && (
          <InputUploadField
            id='checklist'
            label='チェックリストを選択'
            className={classes.inputField}
            defaultValue={''}
            errorMsg={errorMsgs.checklist || errorFileName.toString()}
            checklistRef={defaultValues.checklistRef}
          />
        )}
        {errorChecklistName && (
          <span style={{ marginLeft: 20, fontSize: 12 }}>
            <a className={classes.downloadable} onClick={handleDownload}>
              {errorChecklistName}
            </a>
          </span>
        )}
      </div>
    </>
  );
};
