import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE, USER_TYPE } from 'utils/constants';
import { getHIEById } from 'utils/functions';
import { InspectionSubCategoryContext } from 'views/pages/InspectionSubcategory';
import { InspectionSubCategoryForm } from 'views/components/forms/InspectionSubCategoryForm';
import { DialogButtonUpdate } from 'views/atoms/buttons/dialogs/Update';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';

import { useSetAtom } from 'jotai';
import {
  errorFileNameAtom,
  errorChecklistNameAtom,
  errorFileUuidAtom,
} from 'jotai/atoms';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const UpdateDialog: React.FC<{
  checkedInfo;
  setCheckedInfo;
  setCheckedIds;
  isDisabled: boolean;
}> = (props) => {
  const classes = useStyles();

  const {
    owner_user_type,
    pref_code,
    bank_code,
    defaultValues,
    setErrorMsgs,
    eventHandlers,
    selectedFiscalYear,
    setData,
    setOrgData,
    setRegCategory,
    approvedInfo,
    selectedCategory,
    checkedInfos,
    setCheckedInfos,
    setApprovedIds,
    approvedIds,
  } = React.useContext(InspectionSubCategoryContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const setErrorFileName = useSetAtom(errorFileNameAtom);
  const setErrorChecklistName = useSetAtom(errorChecklistNameAtom);
  const setErrorFileUuid = useSetAtom(errorFileUuidAtom);

  const handleOpen = () => {
    setRegCategory([
      {
        key: props.checkedInfo.category_code,
        val: props.checkedInfo.category_name,
      },
    ]);

    eventHandlers.setSubcategoryName(props.checkedInfo.subcategory_name);
    eventHandlers.setComment(props.checkedInfo.comment);

    setIsDialogOpen(true);
  };

  const handleClose = () => {
    eventHandlers.setSubcategoryName('');
    eventHandlers.setComment('');

    setErrorMsgs.subcategoryName('');
    setErrorMsgs.comment('');
    setErrorMsgs.checklist('');

    setErrorFileName('');
    setErrorChecklistName('');
    setErrorFileUuid('');

    setIsDialogOpen(false);
  };

  const handleUpdate = () => {
    const formData = new FormData();

    const reupload =
      document.querySelector<HTMLInputElement>('#reupload').checked;

    formData.append('fiscal_year', selectedFiscalYear);
    formData.append('owner_user_type', owner_user_type);
    formData.append(
      'target_owner_user_type',
      props.checkedInfo.owner_user_type
    );
    formData.append('pref_code', pref_code);
    formData.append('bank_code', bank_code);
    const categoryCode = document.querySelector<HTMLSelectElement>(
      '#inspection-category'
    );
    formData.append('category_code', categoryCode.value);
    formData.append('subcategory_code', props.checkedInfo.subcategory_code);
    formData.append(
      'subcategory_name',
      document.querySelector<HTMLInputElement>('#inspection-subcategory-name')
        .value
    );

    formData.append(
      'org_subcategory_name',
      props.checkedInfo.org_subcategory_name
    );
    formData.append(
      'comment',
      document.querySelector<HTMLInputElement>('#comment').value
    );
    formData.append('reupload', reupload ? 'reupload' : '');
    if (reupload) {
      formData.append('checklist', defaultValues.checklistRef.current.files[0]);
    }

    axios
      .post('/api/inspection-subcategory/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        const tmpMap = new Map();
        const approvedMap = new Map();

        approvedInfo
          .filter((x) => {
            return x.pref_code === pref_code;
          })
          .map((x) => {
            const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;
            approvedMap.set(key, 'dummy');
          });

        res.data
          .filter((x) => {
            return x.fiscal_year == selectedFiscalYear;
          })
          .filter((x) => {
            return selectedCategory == '0'
              ? true
              : x.category_code == categoryCode.value;
          })
          .map((x) => {
            const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;

            {
              const key1 = `${selectedFiscalYear}:${pref_code}:${bank_code}:${categoryCode.value}:${props.checkedInfo.subcategory_code}`;
              const key2 = `${x.fiscal_year}:${x.pref_code}:${x.bank_code}:${x.category_code}:${x.subcategory_code}`;
              const key3 = `${x.category_code}:${x.subcategory_code}`;

              if (key1 === key2) {
                props.setCheckedInfo({
                  ...props.checkedInfo,
                  ...{
                    id: x.id,
                    subcategory_name: getHIEById('#inspection-subcategory-name')
                      .value,
                    comment: getHIEById('#comment').value,
                  },
                });

                setCheckedInfos([
                  {
                    ...checkedInfos,
                    ...{
                      id: x.id,
                      subcategory_name: getHIEById(
                        '#inspection-subcategory-name'
                      ).value,
                      comment: getHIEById('#comment').value,
                    },
                  },
                ]);

                props.setCheckedIds([x.id]);

                {
                  console.log(approvedIds);

                  const tmp = new Map();

                  approvedIds.forEach((val, key) => {
                    if (val === key3) {
                      tmp.set(x.id, val);
                    } else {
                      tmp.set(key, val);
                    }
                  });

                  setApprovedIds(tmp);
                }
              }
            }

            switch (parseInt(owner_user_type)) {
              case USER_TYPE.SYSTEM_ADMIN:
                if (!x.pref_code && !x.bank_code) {
                  tmpMap.set(key, x);
                }
                break;

              case USER_TYPE.PREF_ADMIN:
                if (
                  (!x.pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && !x.bank_code)
                ) {
                  if (approvedMap.get(key)) {
                    x['check_status'] = 1;
                  } else {
                    x['check_status'] = 0;
                  }
                  tmpMap.set(key, x);
                }
                break;

              case USER_TYPE.JA_ADMIN:
                if (
                  (!x.pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && x.bank_code === bank_code)
                ) {
                  if (
                    x.owner_user_type === USER_TYPE.JA_ADMIN ||
                    approvedMap.get(key)
                  ) {
                    tmpMap.set(key, x);
                  }
                }
                break;
            }
          });

        setData(Array.from(tmpMap.values()));
        setOrgData(res.data);

        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.subcategory_name
          ? setErrorMsgs.subcategoryName(errors.subcategory_name.errorMsg)
          : setErrorMsgs.subcategoryName('');

        errors.comment
          ? setErrorMsgs.comment(errors.comment.errorMsg)
          : setErrorMsgs.comment('');

        errors.checklist
          ? setErrorMsgs.checklist(errors.checklist.errorMsg)
          : setErrorMsgs.checklist('');

        if (errors.file) {
          setErrorFileName('※ 以下のリンクからエラー内容をご確認ください。');

          setErrorChecklistName(
            `【エラー】${
              categoryCode.options[categoryCode.selectedIndex].text
            }_${
              document.querySelector<HTMLInputElement>(
                '#inspection-subcategory-name'
              ).value
            }.xlsx`
          );

          setErrorFileUuid(err.response.data.uuid);
        } else {
          setErrorFileName('');
          setErrorChecklistName('');
          setErrorFileUuid('');
        }
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonUpdate
        buttonLabel='チェックリスト変更'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleUpdate
          titleName='チェックリスト変更'
          onClickActionHandler={handleClose}
        />

        <Divider />

        {(Number(owner_user_type) !== USER_TYPE.PREF_ADMIN ||
          (Number(owner_user_type) === USER_TYPE.PREF_ADMIN &&
            props.checkedInfo.check_status === 0)) && (
          <DialogContent>
            <InspectionSubCategoryForm
              isDisabled={{
                category: true,
                subcategoryName:
                  owner_user_type !== props.checkedInfo.owner_user_type,
              }}
              isHidden={{
                checklist: false,
                checkbox: false,
              }}
              procType={PROC_TYPE.UPDATE}
            />
          </DialogContent>
        )}

        <Divider />

        {(Number(owner_user_type) !== USER_TYPE.PREF_ADMIN ||
          (Number(owner_user_type) === USER_TYPE.PREF_ADMIN &&
            props.checkedInfo.check_status === 0)) && (
          <DialogActionsUpdate
            buttonLabel='チェックリストを変更する'
            onClickActionHandler={handleUpdate}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default UpdateDialog;
