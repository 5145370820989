import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PROC_TYPE } from 'utils/constants';
import { getHIEById } from 'utils/functions';
import { InspectionCategoryContext } from 'views/pages/InspectionCategory';
import { InspectionCategoryForm } from 'views/components/forms/InspectionCategoryForm';
import { DialogButtonUpdate } from 'views/atoms/buttons/dialogs/Update';
import { DialogTitleUpdate } from 'views/molecules/dialogs/titles/Update';
import { DialogActionsUpdate } from 'views/molecules/dialogs/actions/Update';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const UpdateDialog: React.FC<{
  checkedInfo;
  isDisabled: boolean;
}> = (props) => {
  const classes = useStyles();

  const {
    owner_user_type,
    setData,
    setOrgData,
    setErrorCategoryName,
    currFiscalYear,
  } = useContext(InspectionCategoryContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setErrorCategoryName('');
    setIsDialogOpen(false);
  };

  const handleUpdate = () => {
    const categoryName = getHIEById('#category-name').value;
    axios
      .post('/api/inspection-category/update', {
        fiscal_year: currFiscalYear,
        owner_user_type,
        category_code: props.checkedInfo.category_code,
        category_name: categoryName,
        org_category_name: props.checkedInfo.category_name,
      })
      .then((res) => {
        const tmp = res.data.filter((x) => {
          return x.fiscal_year == currFiscalYear;
        });

        setData(tmp);
        setOrgData(res.data);

        props.checkedInfo.category_name = categoryName;
        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;

        errors.category_name
          ? setErrorCategoryName(errors.category_name.errorMsg)
          : setErrorCategoryName('');
      });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonUpdate
        buttonLabel='チェックリスト区分変更'
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleUpdate
          titleName='チェックリスト区分変更'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent style={{ height: '100%' }}>
          <InspectionCategoryForm
            defaultValue={props.checkedInfo.category_name}
            procType={PROC_TYPE.UPDATE}
          />
        </DialogContent>

        <Divider />

        <DialogActionsUpdate
          buttonLabel='チェックリスト区分を変更する'
          onClickActionHandler={handleUpdate}
        />
      </Dialog>
    </Box>
  );
};

export default UpdateDialog;
