import React from 'react';
import axios from 'axios';
import { useAtom } from 'jotai';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  Box,
  Dialog,
  Divider,
  DialogContent,
  TextField,
  makeStyles,
  FormHelperText,
  Button,
} from '@material-ui/core';

import { ChecklistVerificationContext } from 'views/pages/ChecklistVerification';
import { convertJST, downloadExcel } from 'utils/functions';
import { PAR_PAGE, USER_TYPE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles2 = makeStyles({
  inputField: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

export const DataTable: React.FC<{
  yearOption;
}> = (props) => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const {
    fiscal_year,
    yearOption,
    pref_code,
    bank_code,
    data,
    dept_code_lv2,
    deptLv2Option,
    dept_code_lv3,
    deptLv3Option,
    dept_code_lv4,
    deptLv4Option,
    dept_code_lv5,
    deptLv5Option,
    user_type,
    mapVerificationHist,
    setMapVerificationHist,
    user,
    mapIsDeleted,
    setMapIsDeleted,
    inspectionMonth,
    selectedInspectionMonth,
    setIsDialogOpenDel,
    setDeleteInfo,
    mapVerification,
    setDispInfo,
    setSelectedDelId,
    setSelectedKey,
  } = React.useContext(ChecklistVerificationContext);

  const parPage = PAR_PAGE;

  const [offset, setOffset] = useAtom(offsetAtom);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const [yearStr, setYearStr] = React.useState('');
  const [deptLv2Str, setDeptLv2Str] = React.useState('');
  const [deptLv3Str, setDeptLv3Str] = React.useState('');
  const [deptLv4Str, setDeptLv4Str] = React.useState('');
  const [deptLv5Str, setDeptLv5Str] = React.useState('');
  const [chkLstName, setChkLstName] = React.useState('');

  const [catgCode, setCatgCode] = React.useState(0);
  const [subcCode, setSubcCode] = React.useState(0);
  const [deptLvCode, setDeptLvCode] = React.useState(0);
  const [deptLv2, setDeptLv2] = React.useState(0);
  const [deptLv3, setDeptLv3] = React.useState(0);
  const [deptLv4, setDeptLv4] = React.useState(0);
  const [deptLv5, setDeptLv5] = React.useState(0);

  const [returnedReason, setReturnedReason] = React.useState('');

  const [errorMsg, setErrorMsg] = React.useState('');

  const handlePagination = (offset: number) => {
    setOffset(offset);
  };

  const handleDownload = async (
    owner_user_type: string,
    pref_code: string,
    bank_code: string,
    fiscal_year: string,
    inspection_month: number,
    inspectionMonthVal: string,
    category_code: number,
    category_name: string,
    subcategory_code: number,
    subcategory_name: string,
    department_level_code: string,
    dept_code_lv2: string,
    dept_code_lv3: string,
    dept_code_lv4: string,
    dept_code_lv5: string
  ) => {
    const res = await axios.post(
      '/api/checklist-confirm/downloadChecklist',
      {
        owner_user_type,
        pref_code,
        bank_code,
        fiscal_year,
        inspection_month,
        inspectionMonthVal,
        category_code,
        category_name: category_name.substring(2),
        subcategory_code,
        subcategory_name: subcategory_name.substring(2),
        department_level_code,
        dept_code_lv2,
        dept_code_lv3,
        dept_code_lv4,
        dept_code_lv5,
      },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  const handleOpen = (
    category_code,
    category_name,
    subcategory_code,
    subcategory_name,
    owner_user_type,
    department_level_code,
    dept_code_lv2,
    dept_code_lv3,
    dept_code_lv4,
    dept_code_lv5
  ) => {
    setIsDialogOpen(true);

    setYearStr(
      yearOption.filter((x) => {
        return x.key === fiscal_year;
      })[0].val
    );

    setDeptLv2Str(
      deptLv2Option.filter((x) => {
        return x.key === dept_code_lv2;
      })[0].val
    );

    setDeptLv3Str(
      deptLv3Option.filter((x) => {
        return x.key === dept_code_lv3;
      })[0].val
    );

    if (dept_code_lv4) {
      setDeptLv4Str(
        deptLv4Option.filter((x) => {
          return x.key === dept_code_lv4;
        })[0].val
      );
    }

    if (dept_code_lv5) {
      setDeptLv5Str(
        deptLv5Option.filter((x) => {
          return x.key === dept_code_lv5;
        })[0].val
      );
    }

    setCatgCode(category_code);
    setSubcCode(subcategory_code);

    setDeptLvCode(department_level_code);
    setDeptLv2(dept_code_lv2);
    setDeptLv3(dept_code_lv3);
    setDeptLv4(dept_code_lv4);
    setDeptLv5(dept_code_lv5);

    setChkLstName(
      `${category_name.substring(2)}_${subcategory_name.substring(2)}.xlsx`
    );

    setReturnedReason('');
    setErrorMsg('');
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleApprove = () => {
    setErrorMsg('');

    axios
      .post('/api/checklist-confirm/approve-checklist', {
        fiscal_year,
        pref_code,
        bank_code,
        inspection_month: Number(selectedInspectionMonth)
          ? Number(selectedInspectionMonth)
          : 1,
        category_code: catgCode,
        subcategory_code: subcCode,
        department_level_code: deptLvCode,
        dept_code_lv2: deptLv2,
        dept_code_lv3: deptLv3,
        dept_code_lv4: deptLv4,
        dept_code_lv5: deptLv5,
        user,
      })
      .then((res) => {
        res.data.hist
          .filter((x) => {
            return !x.is_deleted;
          })
          .forEach((x) => {
            const key = [
              x.fiscal_year,
              x.pref_code,
              x.bank_code,
              x.inspection_month,
              x.category_code,
              x.subcategory_code,
              x.department_level_code,
              x.dept_code_lv2,
              x.dept_code_lv3,
              x.dept_code_lv4,
              x.dept_code_lv5,
              x.verified_department_level_code,
              x.verified_dept_code_lv1,
              x.verified_dept_code_lv2,
              x.verified_dept_code_lv3,
              x.verified_dept_code_lv4,
              x.verified_dept_code_lv5,
            ].join(':');

            mapVerificationHist.set(key, x.is_approved);
          });

        setMapVerificationHist(new Map(mapVerificationHist));

        res.data.hist
          .filter((x) => {
            return x.is_deleted;
          })
          .forEach((x) => {
            const key = [
              x.fiscal_year,
              x.pref_code,
              x.bank_code,
              x.inspection_month,
              x.category_code,
              x.subcategory_code,
              x.department_level_code,
              x.dept_code_lv2,
              x.dept_code_lv3,
              x.dept_code_lv4,
              x.dept_code_lv5,
              x.verified_department_level_code,
              x.verified_dept_code_lv1,
              x.verified_dept_code_lv2,
              x.verified_dept_code_lv3,
              x.verified_dept_code_lv4,
              x.verified_dept_code_lv5,
            ].join(':');

            mapIsDeleted.set(key, x.is_deleted);
          });

        setMapIsDeleted(new Map(mapIsDeleted));

        handleClose();
      });
  };

  const handleReturn = () => {
    setErrorMsg('');

    axios
      .post('/api/checklist-confirm/return-checklist', {
        fiscal_year,
        pref_code,
        bank_code,
        inspection_month: Number(selectedInspectionMonth)
          ? Number(selectedInspectionMonth)
          : 1,
        category_code: catgCode,
        subcategory_code: subcCode,
        department_level_code: deptLvCode,
        dept_code_lv2: deptLv2,
        dept_code_lv3: deptLv3,
        dept_code_lv4: deptLv4,
        dept_code_lv5: deptLv5,
        returned_reason: returnedReason,
        user,
      })
      .then((res) => {
        res.data.hist
          .filter((x) => {
            return !x.is_deleted;
          })
          .forEach((x) => {
            const key = [
              x.fiscal_year,
              x.pref_code,
              x.bank_code,
              x.inspection_month,
              x.category_code,
              x.subcategory_code,
              x.department_level_code,
              x.dept_code_lv2,
              x.dept_code_lv3,
              x.dept_code_lv4,
              x.dept_code_lv5,
              x.verified_department_level_code,
              x.verified_dept_code_lv1,
              x.verified_dept_code_lv2,
              x.verified_dept_code_lv3,
              x.verified_dept_code_lv4,
              x.verified_dept_code_lv5,
            ].join(':');

            mapVerificationHist.set(key, x.is_approved);
          });

        setMapVerificationHist(new Map(mapVerificationHist));

        res.data.hist
          .filter((x) => {
            return x.is_deleted;
          })
          .forEach((x) => {
            const key = [
              x.fiscal_year,
              x.pref_code,
              x.bank_code,
              x.inspection_month,
              x.category_code,
              x.subcategory_code,
              x.department_level_code,
              x.dept_code_lv2,
              x.dept_code_lv3,
              x.dept_code_lv4,
              x.dept_code_lv5,
              x.verified_department_level_code,
              x.verified_dept_code_lv1,
              x.verified_dept_code_lv2,
              x.verified_dept_code_lv3,
              x.verified_dept_code_lv4,
              x.verified_dept_code_lv5,
            ].join(':');

            mapIsDeleted.set(key, x.is_deleted);
          });

        setMapIsDeleted(new Map(mapIsDeleted));

        handleClose();
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        setErrorMsg(errors.returned_reason.errorMsg);
      });
  };

  const handleDelete = (x) => {
    const key = [
      x.fiscal_year,
      x.pref_code,
      x.bank_code,
      x.inspection_month,
      x.category_code,
      x.subcategory_code,
      x.department_level_code,
      x.dept_code_lv1,
      x.dept_code_lv2,
      x.dept_code_lv3,
      x.dept_code_lv4,
      x.dept_code_lv5,
    ].join(':');

    setSelectedKey(key);

    const tmpAry = [];
    mapVerification.get(key).forEach((x) => {
      tmpAry.push(
        [
          x.id,
          x.verified_user_name,
          convertJST(x.updatedAt),
          x.returned_reason,
        ].join('#$%&%$#')
      );
    });
    setDispInfo(tmpAry);

    setDeleteInfo({
      id: x.id,
      inspectionYear: props.yearOption.find((elm) => elm.key === fiscal_year)
        .val,
      inspectionMonth: inspectionMonth[selectedInspectionMonth - 1]?.val,
      chklstName: `${x.category_name.substring(
        2
      )}_${x.subcategory_name.substring(2)}.xlsx`,
      createdAt: convertJST(x.updated_at),
      fiscal_year,
      pref_code,
      bank_code,
      inspection_month: Number(selectedInspectionMonth)
        ? Number(selectedInspectionMonth)
        : 1,
      category_code: x.category_code,
      subcategory_code: x.subcategory_code,
      department_level_code: x.department_level_code,
      dept_code_lv2: x.dept_code_lv2,
      dept_code_lv3: x.dept_code_lv3,
      dept_code_lv4: x.dept_code_lv4,
      dept_code_lv5: x.dept_code_lv5,
    });

    setSelectedDelId([]);

    setIsDialogOpenDel(true);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow>
              <StyledTableCell style={{ width: '15%', paddingLeft: 50 }}>
                対象月
              </StyledTableCell>
              <StyledTableCell style={{ width: '35%' }}>
                チェックリスト名
              </StyledTableCell>
              <StyledTableCell align='center'>登録日時</StyledTableCell>
              <StyledTableCell align='center'></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.slice(offset, offset + parPage).map((x, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell style={{ paddingLeft: 50 }}>
                  {inspectionMonth[selectedInspectionMonth - 1]?.val ||
                    selectedInspectionMonth}
                </StyledTableCell>
                <StyledTableCell>
                  <span
                    className={classes.downloadable}
                    onClick={() => {
                      handleDownload(
                        x.owner_user_type,
                        pref_code,
                        bank_code,
                        x.fiscal_year,
                        x.inspection_month,
                        inspectionMonth[selectedInspectionMonth - 1]?.val ||
                          selectedInspectionMonth,
                        x.category_code,
                        x.category_name,
                        x.subcategory_code,
                        x.subcategory_name,
                        x.department_level_code,
                        dept_code_lv2,
                        dept_code_lv3,
                        dept_code_lv4,
                        dept_code_lv5
                      );
                    }}
                  >
                    {`${x.category_name.substring(
                      2
                    )}_${x.subcategory_name.substring(2)}.xlsx`}
                  </span>
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {x.updated_at ? convertJST(x.updated_at) : '-'}
                </StyledTableCell>

                {user_type === USER_TYPE.USER ? (
                  <StyledTableCell align='center'>
                    {(() => {
                      const key = [
                        fiscal_year,
                        pref_code,
                        bank_code,
                        x.inspection_month,
                        x.category_code,
                        x.subcategory_code,
                        x.department_level_code,
                        x.dept_code_lv2,
                        x.dept_code_lv3,
                        x.dept_code_lv4,
                        x.dept_code_lv5,
                        user.department_level_code,
                        user.dept_code_lv1,
                        user.dept_code_lv2,
                        user.dept_code_lv3,
                        user.dept_code_lv4,
                        user.dept_code_lv5,
                      ].join(':');

                      if (mapVerificationHist.has(key)) {
                        if (mapVerificationHist.get(key)) {
                          return (
                            <Button
                              variant='outlined'
                              style={{
                                color: '#006633',
                                pointerEvents: 'none',
                              }}
                              onClick={() =>
                                handleOpen(
                                  x.category_code,
                                  x.category_name,
                                  x.subcategory_code,
                                  x.subcategory_name,
                                  x.owner_user_type,
                                  x.department_level_code,
                                  x.dept_code_lv2,
                                  x.dept_code_lv3,
                                  x.dept_code_lv4,
                                  x.dept_code_lv5
                                )
                              }
                            >
                              承認済
                            </Button>
                          );
                        } else {
                          return (
                            <Button
                              color='secondary'
                              variant='outlined'
                              style={{ pointerEvents: 'none' }}
                              onClick={() =>
                                handleOpen(
                                  x.category_code,
                                  x.category_name,
                                  x.subcategory_code,
                                  x.subcategory_name,
                                  x.owner_user_type,
                                  x.department_level_code,
                                  x.dept_code_lv2,
                                  x.dept_code_lv3,
                                  x.dept_code_lv4,
                                  x.dept_code_lv5
                                )
                              }
                            >
                              差戻済
                            </Button>
                          );
                        }
                      } else {
                        return (
                          <Button
                            color={
                              mapIsDeleted.has(key) ? 'secondary' : 'primary'
                            }
                            variant='outlined'
                            onClick={() =>
                              handleOpen(
                                x.category_code,
                                x.category_name,
                                x.subcategory_code,
                                x.subcategory_name,
                                x.owner_user_type,
                                x.department_level_code,
                                x.dept_code_lv2,
                                x.dept_code_lv3,
                                x.dept_code_lv4,
                                x.dept_code_lv5
                              )
                            }
                          >
                            {mapIsDeleted.has(key) ? '再検証' : '検証'}
                          </Button>
                        );
                      }
                    })()}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align='center'>
                    {mapVerification.has(
                      [
                        x.fiscal_year,
                        x.pref_code,
                        x.bank_code,
                        x.inspection_month,
                        x.category_code,
                        x.subcategory_code,
                        x.department_level_code,
                        x.dept_code_lv1,
                        x.dept_code_lv2,
                        x.dept_code_lv3,
                        x.dept_code_lv4,
                        x.dept_code_lv5,
                      ].join(':')
                    ) ? (
                      <Button
                        color={'secondary'}
                        variant='outlined'
                        onClick={() => handleDelete(x)}
                      >
                        取消
                      </Button>
                    ) : (
                      <Button
                        variant='outlined'
                        style={{
                          color: 'white',
                          border: '1px solid white',
                          pointerEvents: 'none',
                        }}
                      >
                        なし
                      </Button>
                    )}
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box component='span' style={{}}>
        <Dialog
          open={isDialogOpen}
          onClose={handleClose}
          aria-labelledby='form-dialog-title'
          fullWidth
          maxWidth={'md'}
          classes={{
            paper: classes2.paper,
          }}
        >
          <DialogTitleCreate
            titleName='検査結果の検証'
            onClickActionHandler={handleClose}
          />

          <div style={{ marginTop: 32, marginLeft: 32 }}>
            <div style={{ fontSize: 16, margin: 0 }}>検査年度：{yearStr}</div>
            <div style={{ fontSize: 16, marginTop: 8 }}>
              検査部署：{deptLv2Str}&ensp;
              {deptLv3Str}&ensp;
              {deptLv4Str}&ensp;
              {deptLv5Str}&ensp;
            </div>
            <div style={{ fontSize: 16, marginTop: 8 }}>
              検査対象月：
              {inspectionMonth[selectedInspectionMonth - 1]?.val ||
                selectedInspectionMonth}
            </div>
            <div style={{ fontSize: 16, marginTop: 8 }}>
              チェックリスト名：{chkLstName}
            </div>
          </div>

          <DialogContent>
            <DialogActionsCreate
              buttonLabel='承認'
              onClickActionHandler={handleApprove}
            />

            <Divider />

            <TextField
              label='差戻理由'
              variant='outlined'
              color='secondary'
              multiline
              rows={3}
              style={{ width: '100%', marginTop: 32 }}
              onChange={(e) => {
                setReturnedReason(e.target.value);
              }}
            />
            <FormHelperText error={errorMsg.trim().length > 0}>
              {errorMsg}
            </FormHelperText>

            <DialogActionsDelete
              buttonLabel='差戻'
              onClickActionHandler={handleReturn}
            />
          </DialogContent>
        </Dialog>
      </Box>

      {data?.length > PAR_PAGE && (
        <JICSPagination
          length={data.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};
