import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';

import axios from 'axios';

import { downloadExcel } from 'utils/functions';
import {
  API_ENDPOINT,
  CHECKLIST_KBN_OPT,
  CHECKLIST_NAME_OPT,
  NO_OPT,
  SELECTBOX_DISPLAY_CONTROL,
  USER_TYPE,
} from 'utils/constants';

import { TplPage } from 'views/templates/TplPage';
import { SelectControl } from 'views/molecules/SelectControl';

const ExcelDownload: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const user_type = user.user_type;
  const pref_code =
    user.pref_code === undefined || user.pref_code === null
      ? ''
      : user.pref_code;
  const dept_code = user.department_level_code;
  const bank_code =
    user.bank_code === undefined || user.bank_code === null
      ? ''
      : user.bank_code;
  const dept_code_lv1 = user.dept_code_lv1;
  const dept_code_lv2 = user.dept_code_lv2;
  const dept_code_lv3 = user.dept_code_lv3;
  const dept_code_lv4 = user.dept_code_lv4;
  const dept_code_lv5 = user.dept_code_lv5;

  // 帳票入力種類（1：期間 2：カテゴリー 3：部署 4：エリア）
  const [reportType, setReportType] = useState([]);
  // 部署LV2表示フラグ true：表示しない false:表示する
  const [deptLv2Invalid, setDeptLv2Invalid] = useState<boolean>(true);

  // カテゴリーデータ
  const [categData, setCategData] = useState([]);
  // 部署リストデータ
  const [deptData, setDeptData] = useState([]);

  const [orgData, setOrgData] = React.useState([]);

  // 「年度」
  const [yearOpt, setYearOpt] = useState([]);
  const [yearKey, setYearKey] = useState<number>(0);
  // 「帳票」
  const [reportOpt, setReportOpt] = useState([]);
  const [reportKey, setReportKey] = useState(0);
  // 「都道府県」
  const [prefOpt, setPrefOpt] = useState([]);
  const [prefKey, setPrefKey] = useState<string>('');
  // 「JA」
  const [bankOpt, setBankOpt] = useState([]);
  const [bankKey, setBankKey] = useState<string>('');
  // 「頻度」
  const [freqOpt, setFreqOpt] = useState([]);
  const [freqKey, setFreqKey] = useState<number>(0);
  // 「期間」
  const [termOptFr, setTermOptFr] = useState([]);
  const [termKeyFr, setTermKeyFr] = useState<number>(0);
  const [termOptTo, setTermOptTo] = useState([]);
  const [termKeyTo, setTermKeyTo] = useState<number>(0);
  // 「チェックリスト区分」
  const [categOpt, setCategOpt] = useState([]);
  const [categKey, setCategKey] = useState(0);
  // 「チェックリスト」
  const [subCategOpt, setSubCategOpt] = useState([]);
  const [subCategKey, setSubCategKey] = useState<string | number>('');
  // 「階層レベルⅡの所属部署」
  const [dept2Opt, setDept2Opt] = useState([]);
  const [dept2Key, setDept2Key] = useState<string | number>('');
  // 「階層レベルⅢの所属部署」
  const [dept3Opt, setDept3Opt] = useState([]);
  const [dept3Key, setDept3Key] = useState<string | number>('');
  // 「階層レベルⅣの所属部署」
  const [dept4Opt, setDept4Opt] = useState([]);
  const [dept4Key, setDept4Key] = useState<string | number>('');
  // 「階層レベルⅤの所属部署」
  const [dept5Opt, setDept5Opt] = useState([]);
  const [dept5Key, setDept5Key] = useState<string | number>('');

  const [mapStmn, setMapStmn] = useState(new Map());

  const [mapInitialMonth, setMapInitialMonth] = React.useState(new Map());

  const handleChangeFiscalYear = (e) => {
    const keyYear = parseInt(e.target.value);
    setYearKey(keyYear);

    setReportKey(1);
    setReportType([]);
  };

  /*
   * 「帳票」選択onChange
   */
  const handleChangeReport = (e) => {
    const reportCode = parseInt(e.target.value);
    setReportKey(reportCode);

    const key = `${yearKey}:${prefKey}:${bankKey}`;
    setFreqKey(mapInitialMonth.get(key));
    setTermKeyFr(mapInitialMonth.get(key));
    setTermKeyTo(mapInitialMonth.get(key));
  };

  /*
   * 「頻度」選択onChange
   */
  const handleChangeFreq = (e) => {
    setFreqKey(parseInt(e.target.value));
  };

  /*
   * 「都道府県」選択onChange
   */
  const handleChangeArea = (e) => {
    setPrefKey(e.target.value);
  };

  /*
   * 「JA」選択onChange
   */
  const handleChangeJa = (e) => {
    setBankKey(e.target.value);
  };

  /*
   * 「期間」選択onChange
   */
  const handleChangeTermFr = (e) => {
    const key = parseInt(e.target.value);

    setTermKeyFr(key);

    const tmp = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      .slice(key - 1)
      .map((i) => {
        const month = parseInt(mapStmn.get(`${yearKey}${bankKey}`)) + i - 1;

        return {
          key: i,
          val: `${month > 12 ? month - 12 : month}月`,
        };
      });

    setTermOptTo(tmp);
    setTermKeyTo(key);
  };

  const handleChangeTermTo = (e) => {
    setTermKeyTo(parseInt(e.target.value));
  };

  /*
   * 「チェックリスト区分」選択onChange
   */
  const handleChangeCateg = (e) => {
    setCategKey(e.target.value);
  };

  /*
   * 「チェックリスト」選択onChange
   */
  const handleChangeSubCateg = (e) => {
    setSubCategKey(e.target.value);
  };

  // 該当「JA」の部署lv2存在するか判断する
  const isDeptLv2Invalid = (areaRes, pref_code, bank_code) => {
    const isInvalid = areaRes
      .filter((x) => {
        return x.pref_code == pref_code && x.bank_code == bank_code;
      })
      .map((x) => {
        return x.is_dept_lv2_invalid;
      })[0];

    return isInvalid == null ? false : isInvalid;
  };

  // 「チェックリスト」データ取得
  const createSubCategOpt = (
    categData,
    pref_code,
    bank_code,
    category_code
  ) => {
    const tmpMap = new Map();

    categData
      .filter((x) => {
        return x.fiscal_year === yearKey;
      })
      .filter((x) => {
        return (
          x.owner_user_type === 1 ||
          (x.owner_user_type === 3 && x.pref_code === pref_code) ||
          (x.owner_user_type === 4 &&
            x.pref_code === pref_code &&
            x.bank_code === (reportKey === 21 ? x.bank_code : bank_code))
        );
      })
      .filter((x) => {
        return x.category_code == category_code;
      })
      .map((x) => {
        tmpMap.set(x.subcategory_code, {
          key: x.subcategory_code,
          val: x.subcategory_name,
        });
      });

    return Array.from(tmpMap.values());
  };

  /*
   * 階層レベルⅡの所属部署の制御メソッド
   */
  const handleChangeDepLv2 = (e) => {
    const keyLv2 = parseInt(e.target.value);
    setDept2Key(keyLv2);

    if (keyLv2 === 0) {
      clearDeptLv345(2);
    } else {
      const tmpLv3 = [];

      deptData
        .filter((x) => {
          return (
            x.fiscal_year === yearKey &&
            x.pref_code === prefKey &&
            x.bank_code === bankKey
          );
        })
        .map((x) => {
          if (
            x.dept_code_lv3 &&
            x.dept_name_lv3 &&
            x.dept_code_lv2 === keyLv2
          ) {
            tmpLv3.push({ key: x.dept_code_lv3, val: x.dept_name_lv3 });
          }
        });

      let aryLv3 = Array.from(new Map(tmpLv3.map((x) => [x.key, x])).values());

      if (aryLv3.length > 1) {
        aryLv3 = [...[{ key: 0, val: '全部署' }], ...aryLv3];
      }

      setDept3Key(aryLv3[0]?.key);

      setDept3Opt(aryLv3);
      setDept4Opt([]);
      setDept5Opt([]);
    }
  };

  /*
   * 階層レベルⅢの所属部署の制御メソッド
   */
  const handleChangeDepLv3 = (e) => {
    const keyLv3 = parseInt(e.target.value);
    setDept3Key(keyLv3);

    if (keyLv3 === 0) {
      clearDeptLv345(3);
    } else {
      const tmpLv4 = [];

      deptData
        .filter((x) => {
          return (
            x.fiscal_year === yearKey &&
            x.pref_code === prefKey &&
            x.bank_code === bankKey
          );
        })
        .map((x) => {
          if (
            x.dept_code_lv4 &&
            x.dept_name_lv4 &&
            x.dept_code_lv3 === keyLv3
          ) {
            tmpLv4.push({ key: x.dept_code_lv4, val: x.dept_name_lv4 });
          }
        });

      let aryLv4 = Array.from(new Map(tmpLv4.map((x) => [x.key, x])).values());

      if (aryLv4.length === 0) {
        setDept5Opt([]);
      } else if (aryLv4.length === 1) {
        const tmpLv5 = [];

        deptData
          .filter((x) => {
            return (
              x.fiscal_year === yearKey &&
              x.pref_code === prefKey &&
              x.bank_code === bankKey
            );
          })
          .map((x) => {
            if (
              x.dept_code_lv5 &&
              x.dept_name_lv5 &&
              x.dept_code_lv4 === aryLv4[0].key
            ) {
              tmpLv5.push({ key: x.dept_code_lv5, val: x.dept_name_lv5 });
            }
          });

        let aryLv5 = Array.from(
          new Map(tmpLv5.map((x) => [x.key, x])).values()
        );

        if (aryLv5.length > 1) {
          aryLv5 = [...[{ key: 0, val: '全部署' }], ...aryLv5];
        }

        setDept5Key(aryLv5[0]?.key);
        setDept5Opt(aryLv5);
      } else {
        aryLv4 = [...[{ key: 0, val: '全部署' }], ...aryLv4];
      }

      setDept4Key(aryLv4[0]?.key);
      setDept4Opt(aryLv4);
    }
  };

  /*
   * 階層レベルⅣの所属部署の制御メソッド
   */
  const handleChangeDepLv4 = (e) => {
    const keyLv4 = parseInt(e.target.value);
    setDept4Key(keyLv4);

    if (keyLv4 === 0) {
      clearDeptLv345(4);
    } else {
      const tmpLv5 = [];

      deptData
        .filter((x) => {
          return (
            x.fiscal_year === yearKey &&
            x.pref_code === prefKey &&
            x.bank_code === bankKey
          );
        })
        .map((x) => {
          if (
            x.dept_code_lv5 &&
            x.dept_name_lv5 &&
            x.dept_code_lv4 === keyLv4
          ) {
            tmpLv5.push({ key: x.dept_code_lv5, val: x.dept_name_lv5 });
          }
        });

      let aryLv5 = Array.from(new Map(tmpLv5.map((x) => [x.key, x])).values());

      if (aryLv5.length > 1) {
        aryLv5 = [...[{ key: 0, val: '全部署' }], ...aryLv5];
      }

      setDept5Key(aryLv5[0]?.key);

      setDept5Opt(aryLv5);
    }
  };

  /*
   * 階層レベルⅤの所属部署の制御メソッド
   */
  const handleChangeDepLv5 = (e) => {
    setDept5Key(parseInt(e.target.value));
  };

  /*
   * 階層レベル ⅢまたはⅣまたはⅤ の所属部署のクリア処理
   * @param kbn
   */
  const clearDeptLv345 = (kbn) => {
    if (kbn === 2) {
      // 階層レベルⅢ選択内容をクリアする
      setDept3Opt([]);
      setDept3Key('');
      kbn = 3;
    }
    if (kbn === 3) {
      // 階層レベルⅣ選択内容をクリアする
      setDept4Opt([]);
      setDept4Key('');
      kbn = 4;
    }
    if (kbn === 4) {
      // 階層レベルⅤ選択内容をクリアする
      setDept5Opt([]);
      setDept5Key('');
    }
  };

  // 「チェックリスト区分」作成
  const createCategOpt = (categData, pref_code, bank_code) => {
    const categDatas = categData
      .filter((x) => {
        return x.fiscal_year === yearKey;
      })
      .filter((x) => {
        return (
          x.owner_user_type === 1 ||
          (x.owner_user_type === 3 && x.pref_code === pref_code) ||
          (x.owner_user_type === 4 &&
            x.pref_code === pref_code &&
            x.bank_code ===
              (reportKey === 20 || reportKey === 21 ? x.bank_code : bank_code))
        );
      })
      .filter((x) => {
        if (reportKey === 20 || reportKey === 21) {
          if (user_type === 1 || user_type === 2) {
            return x.owner_user_type === 1;
          } else {
            return x.owner_user_type === 1 || x.owner_user_type === 3;
          }
        } else {
          return true;
        }
      })
      .map((elm) => {
        return {
          key: elm.category_code,
          val: elm.category_name,
        };
      })
      .filter(
        (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
      );

    return categDatas;
  };

  // 「帳票種類」フィルター 共通処理
  const createReportOpt = (reportlist, userType: number, depCode: number) => {
    let tmp = [];
    let res = [];

    if (userType === USER_TYPE.SYSTEM_ADMIN) {
      tmp = [...reportlist.filter((elm) => elm.system_admin)];
    } else if (userType === USER_TYPE.ALL_PREF_ADMIN) {
      tmp = [...reportlist.filter((elm) => elm.nrk_admin)];
    } else if (userType === USER_TYPE.PREF_ADMIN) {
      tmp = [...reportlist.filter((elm) => elm.pref_admin)];
    } else if (userType === USER_TYPE.JA_ADMIN) {
      tmp = [...reportlist.filter((elm) => elm.ja_admin)];
    } else {
      if (depCode === 1) {
        tmp = [...reportlist.filter((elm) => elm.ja_lv1)];
      } else if (depCode === 2) {
        tmp = [...reportlist.filter((elm) => elm.ja_lv2)];
      } else if (depCode === 3) {
        tmp = [...reportlist.filter((elm) => elm.ja_lv3)];
      } else if (depCode === 4) {
        tmp = [...reportlist.filter((elm) => elm.ja_lv4)];
      } else if (depCode === 5) {
        tmp = [...reportlist.filter((elm) => elm.ja_lv5)];
      }
    }

    res = tmp.map((elm) => {
      return {
        key: elm.report_code,
        val: elm.report_name,
      };
    });

    setReportOpt(res);
    setReportKey(1);
  };

  useEffect(() => {
    Promise.all([
      // 「帳票」ドロップダウンデータを取得 0
      axios.get('/api/template-report/getlist'),
      // 「エリア」「JA名」 サブ「期間」ドロップダウンデータを取得 2
      axios.get('/api/template-report/getJaMaters', {
        params: {
          pref_code,
          bank_code,
        },
      }),
      // 「部署」ドロップダウンデータを取得 3
      axios.get('/api/template-report/getDepartmentList', {
        params: {
          pref_code,
          bank_code,
          dep_code: dept_code == null ? 0 : dept_code == 1 ? 2 : dept_code,
          dep_code_lv2: dept_code_lv2 == null ? 0 : dept_code_lv2,
          dep_code_lv3: dept_code_lv3 == null ? 0 : dept_code_lv3,
          dep_code_lv4: dept_code_lv4 == null ? 0 : dept_code_lv4,
          dep_code_lv5: dept_code_lv5 == null ? 0 : dept_code_lv5,
        },
      }),
      // 「チェックリスト区分」「チェックリスト」ドロップダウンデータを取得 4
      axios.get('/api/template-report/getInspectionCategories', {
        params: {
          pref_code,
          bank_code,
        },
      }),

      // 「チェック結果」 5
      axios.get('/api/checklist-result'),

      axios.get('/api/fiscal-year-start-month'),

      axios.get('/api/current-inspection-month'),
    ]).then((res) => {
      // res[0]：「帳票」 権限によって、フィルターにする
      createReportOpt(res[0].data, user_type, dept_code);

      // // res[4]：「チェック結果」
      // const filtedRes = filterResultData(res[4]);
      // setOrgData(filtedRes);

      // setYearKey(currYear);

      // // 「エリア」 res[1]
      // const areaRes = res[1].data[0];
      // setAreaData(areaRes);

      // 部署lv2表示制御
      // setDeptLv2Invalid(
      //   isDeptLv2Invalid(areaRes, finalArea[0]?.key, String(jaList[0]?.key))
      // );

      // res[2]：「部署」 所属部署リスト全件取得
      setDeptData(res[2].data[0]);

      // res[3]：「チェックリスト区分」「チェックリスト」 カテゴリーリスト全件取得
      setCategData(res[3].data);

      let currYear;
      {
        const tmp = Array.from(
          res[1].data
            .map((x) => {
              return {
                key: x.fiscal_year,
                val:
                  user_type === USER_TYPE.JA_ADMIN ||
                  user_type === USER_TYPE.USER
                    ? `${x.fiscal_year} 年度` +
                      res[5].data[`${x.fiscal_year}:${x.bank_code}`]
                    : `${x.fiscal_year} 年度`,
              };
            })
            .filter(
              (element, index, self) =>
                self.findIndex((e) => e.key === element.key) === index
            )
        );

        currYear = (tmp[0] as { key: number; val: string }).key as number;

        setYearKey(currYear);
        setYearOpt(tmp);
      }

      let currPref;
      {
        const tmp = res[1].data
          .filter((x) => {
            return x.fiscal_year === currYear;
          })
          .map((x) => {
            return {
              key: x.pref_code,
              val: x.pref_name,
            };
          })
          .filter(
            (element, index, self) =>
              self.findIndex((e) => e.key === element.key) === index
          );

        currPref = tmp[0].key as string;

        setPrefKey(currPref);
        setPrefOpt(tmp);
      }

      let currBank;
      {
        const tmp = res[1].data
          .filter((x) => {
            return x.fiscal_year === currYear && x.pref_code === currPref;
          })
          .filter((x) => {
            return (
              user_type !== USER_TYPE.PREF_ADMIN ||
              (user_type === USER_TYPE.PREF_ADMIN && x.is_public)
            );
          })
          .map((x) => {
            return {
              key: x.bank_code,
              val: x.ja_nickname,
            };
          });

        currBank = tmp[0].key as string;

        setBankKey(currBank);
        setBankOpt(tmp);
      }

      {
        const tmpMapStmn = new Map();
        res[1].data.forEach((x) => {
          tmpMapStmn.set(`${x.fiscal_year}${x.bank_code}`, x.start_month);
        });
        setMapStmn(tmpMapStmn);
      }

      setOrgData(res[1].data);

      const tmpMapCurrMonth = new Map();

      res[6].data.forEach((x) => {
        const key = x.split('#')[0];
        const val = x.split('#')[1];
        tmpMapCurrMonth.set(key, val);
      });

      setMapInitialMonth(tmpMapCurrMonth);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let currPref2;

    if (orgData.length) {
      const tmp = orgData
        .filter((x) => {
          return x.fiscal_year === yearKey;
        })
        .map((x) => {
          return {
            key: x.pref_code,
            val: x.pref_name,
          };
        })
        .filter(
          (element, index, self) =>
            self.findIndex((e) => e.key === element.key) === index
        );

      currPref2 = tmp[0].key as string;

      setPrefKey(currPref2);
      setPrefOpt(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearKey]);

  // 「エリア」を変更すると、「JA名」も連動変更
  useEffect(() => {
    let currBank2;

    if (orgData.length) {
      const tmp = orgData
        .filter((x) => {
          return x.fiscal_year === yearKey && x.pref_code === prefKey;
        })
        .map((x) => {
          return {
            key: x.bank_code,
            val: x.ja_nickname,
          };
        });

      currBank2 = tmp[0].key as string;

      setBankKey(currBank2);
      setBankOpt(tmp);

      const key = `${yearKey}:${prefKey}:${currBank2}`;
      setFreqKey(mapInitialMonth.get(key));
      setTermKeyFr(mapInitialMonth.get(key));
      setTermKeyTo(mapInitialMonth.get(key));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearKey, prefKey]);

  // 「JA名」を変更すると、「チェックリスト区分」も連動変更
  useEffect(() => {
    // 「チェックリスト区分」リスト
    const finalCateg = createCategOpt(
      categData,
      prefKey,
      !reportType.includes(4) ? bankKey : ''
    );

    // 「チェックリスト区分」がないの場合、「－」にする
    if (finalCateg.length === 0) {
      setCategOpt(NO_OPT);
      setCategKey(0);
      // 「帳票」が8、15以外の場合、「チェックリスト区分」に「すべて」を加える
    } else if (
      finalCateg.length === 1 ||
      reportKey == 9 ||
      reportKey == 16 ||
      reportKey == 21
    ) {
      setCategOpt([...finalCateg]);
      setCategKey(finalCateg[0]?.key);
      // 「帳票」が8、15以外の場合、「チェックリスト区分」に「すべて」を加える
    } else {
      setCategOpt([...CHECKLIST_KBN_OPT, ...finalCateg]);
      setCategKey(0);
    }

    const key = `${yearKey}:${prefKey}:${bankKey}`;
    setFreqKey(mapInitialMonth.get(key));
    setTermKeyFr(mapInitialMonth.get(key));
    setTermKeyTo(mapInitialMonth.get(key));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearKey, bankKey, reportKey]);

  // 「チェックリスト区分」を変更すると、「チェックリスト」も連動変更
  useEffect(() => {
    // 「チェックリスト」リスト
    const finalSubCateg = createSubCategOpt(
      categData,
      prefKey,
      !reportType.includes(4) ? bankKey : '',
      categKey
    );

    //「チェックリスト」がないの場合、「－」にする
    if (finalSubCateg.length === 0) {
      setSubCategOpt(NO_OPT);
      setSubCategKey(0);
      // 「帳票」が8、15の場合、「チェックリスト」に「すべて」を除外する
    } else if (
      finalSubCateg.length === 1 ||
      reportKey == 9 ||
      reportKey == 16 ||
      reportKey == 21
    ) {
      setSubCategOpt([...finalSubCateg]);
      setSubCategKey(String(finalSubCateg[0]?.key));
      // 「帳票」が8、15以外 または 「すべて」の場合、「チェックリスト」に「すべて」を加える
    } else {
      setSubCategOpt([...CHECKLIST_NAME_OPT, ...finalSubCateg]);
      setSubCategKey(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearKey, categKey]);

  // 「エリア」、「JA名」を変更すると、「部署」も連動変更
  useEffect(() => {
    // 部署lv2表示制御
    const isLv2Invalid = isDeptLv2Invalid(orgData, prefKey, bankKey);

    // 部署LV2の表示制御の設定
    setDeptLv2Invalid(isLv2Invalid);

    {
      const tmpLv2 = [];
      const tmpLv3 = [];
      const tmpLv4 = [];
      const tmpLv5 = [];

      deptData
        .filter((x) => {
          return (
            x.fiscal_year === yearKey &&
            x.pref_code === prefKey &&
            x.bank_code === bankKey
          );
        })
        .map((x) => {
          if (x.dept_code_lv2 && x.dept_name_lv2) {
            tmpLv2.push({ key: x.dept_code_lv2, val: x.dept_name_lv2 });
          }
          if (x.dept_code_lv3 && x.dept_name_lv3) {
            tmpLv3.push({ key: x.dept_code_lv3, val: x.dept_name_lv3 });
          }
          if (x.dept_code_lv4 && x.dept_name_lv4) {
            tmpLv4.push({ key: x.dept_code_lv4, val: x.dept_name_lv4 });
          }
          if (x.dept_code_lv5 && x.dept_name_lv5) {
            tmpLv5.push({ key: x.dept_code_lv5, val: x.dept_name_lv5 });
          }
        });

      let aryLv2 = Array.from(new Map(tmpLv2.map((x) => [x.key, x])).values());
      let aryLv3 = Array.from(new Map(tmpLv3.map((x) => [x.key, x])).values());
      let aryLv4 = Array.from(new Map(tmpLv4.map((x) => [x.key, x])).values());
      let aryLv5 = Array.from(new Map(tmpLv5.map((x) => [x.key, x])).values());

      if (aryLv2.length > 1) {
        aryLv2 = [...[{ key: 0, val: '全部署' }], ...aryLv2];
        aryLv3 = [];
        aryLv4 = [];
        aryLv5 = [];
      }
      if (aryLv3.length > 1) {
        aryLv3 = [...[{ key: 0, val: '全部署' }], ...aryLv3];
        aryLv4 = [];
        aryLv5 = [];
      }
      if (aryLv4.length > 1) {
        aryLv4 = [...[{ key: 0, val: '全部署' }], ...aryLv4];
        aryLv5 = [];
      }
      if (aryLv5.length > 1) {
        aryLv5 = [...[{ key: 0, val: '全部署' }], ...aryLv5];
      }

      setDept2Key(aryLv2[0]?.key);
      setDept3Key(aryLv3[0]?.key);
      setDept4Key(aryLv4[0]?.key);
      setDept5Key(aryLv5[0]?.key);

      setDept2Opt(aryLv2);
      setDept3Opt(aryLv3);
      setDept4Opt(aryLv4);
      setDept5Opt(aryLv5);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearKey, prefKey, bankKey, reportKey]);

  useEffect(() => {
    const tmp = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
      let month;

      if (reportKey >= 17) {
        const kanji = [
          'dummy',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '1',
          '2',
          '3',
        ];

        month = `${kanji[i]} 月`;
      } else {
        const tmp = parseInt(mapStmn.get(`${yearKey}${bankKey}`)) + i - 1;
        month = `${tmp > 12 ? tmp - 12 : tmp} 月`;
      }

      return {
        key: i,
        val: month,
      };
    });

    setFreqOpt(tmp);
    setTermOptFr(tmp);

    const key = `${yearKey}:${prefKey}:${bankKey}`;
    const tmp2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      .slice(mapInitialMonth.get(key) - 1)
      .map((i) => {
        let month;

        if (reportKey >= 17) {
          const kanji = [
            'dummy',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '1',
            '2',
            '3',
          ];

          month = `${kanji[i]} 月`;
        } else {
          const tmp = parseInt(mapStmn.get(`${yearKey}${bankKey}`)) + i - 1;
          month = `${tmp > 12 ? tmp - 12 : tmp} 月`;
        }

        return {
          key: i,
          val: month,
        };
      });

    setTermOptTo(tmp2);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearKey, reportKey, prefKey, bankKey]);

  /*
   * 「ダウンロード」ボタン 処理
   */
  const handleDownload = () => {
    const url = API_ENDPOINT[reportKey];
    const params = {
      fiscal_year: yearKey,
      pref_code: prefKey,
      pref_name: prefOpt.find((x) => x.key === prefKey).val,
      bank_code: bankKey,
      bank_name: bankOpt.find((x) => x.key === bankKey).val,
      inspection_month: freqKey,
      inspection_month_val: freqOpt.find((x) => x.key == freqKey)?.val,
      inspection_month_fr: termKeyFr,
      inspection_month_fr_val: termOptFr.find((x) => x.key == termKeyFr)?.val,
      inspection_month_to: termKeyTo,
      inspection_month_to_val: termOptTo.find((x) => x.key == termKeyTo)?.val,
      categ: categKey,
      categ_name: categOpt.find((x) => x.key == categKey).val,
      subCateg: subCategKey,
      subCateg_name: subCategOpt.find((x) => x.key == subCategKey).val,
      dept_code,
      keyLv2: dept2Key,
      deptNameLv2: dept2Opt.find((x) => x.key === dept2Key)?.val || '',
      keyLv3: dept3Key,
      deptNameLv3: dept3Opt.find((x) => x.key === dept3Key)?.val || '',
      keyLv4: dept4Key,
      deptNameLv4: dept4Opt.find((x) => x.key === dept4Key)?.val || '',
      keyLv5: dept5Key,
      deptNameLv5: dept5Opt.find((x) => x.key === dept5Key)?.val || '',
      user_type,
      department_level_code: dept_code,
      dept_code_lv1,
      dept_code_lv2,
      dept_code_lv3,
      dept_code_lv4,
      dept_code_lv5,
      inspection_start_month: mapStmn.get(`${yearKey}${bankKey}`),
      isImportant:
        reportKey === 10 ||
        reportKey === 11 ||
        reportKey === 12 ||
        reportKey === 13 ||
        reportKey === 14,
    };

    axios.post(url, params, { responseType: 'blob' }).then((res) => {
      downloadExcel(res);
    });
  };

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            出力帳票
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SelectControl
              label='検査年度を選択'
              style={{ width: '600px' }}
              value={yearKey}
              options={yearOpt}
              onChange={handleChangeFiscalYear}
            />

            <SelectControl
              label='帳票を選択'
              style={{ width: '600px', marginTop: 20 }}
              value={reportKey}
              options={reportOpt}
              onChange={handleChangeReport}
            />

            <div
              style={{
                marginTop: '20px',
                textAlign: 'left',
              }}
            >
              <SelectControl
                label='都道府県を選択'
                style={
                  reportKey >= 17
                    ? { width: '600px' }
                    : { width: '296px', marginRight: 8 }
                }
                value={prefKey}
                options={prefOpt}
                onChange={handleChangeArea}
              />

              {SELECTBOX_DISPLAY_CONTROL[reportKey || 1].jan && (
                <SelectControl
                  label='JAを選択'
                  style={{ width: '296px' }}
                  value={bankKey}
                  options={bankOpt}
                  onChange={handleChangeJa}
                />
              )}
            </div>

            {SELECTBOX_DISPLAY_CONTROL[reportKey || 1].mth && (
              <div
                style={{
                  marginTop: '20px',
                  textAlign: 'left',
                }}
              >
                {reportKey < 4 || reportKey > 16 ? (
                  <SelectControl
                    label='検査対象月を選択'
                    style={{ width: '600px' }}
                    value={freqKey}
                    options={freqOpt}
                    onChange={handleChangeFreq}
                  />
                ) : (
                  <>
                    <SelectControl
                      label='検査対象開始月を選択'
                      style={{ width: '296px', marginRight: 8 }}
                      value={termKeyFr}
                      options={termOptFr}
                      onChange={handleChangeTermFr}
                    />

                    <SelectControl
                      label='検査対象終了月を選択'
                      style={{ width: '296px' }}
                      value={termKeyTo}
                      options={termOptTo}
                      onChange={handleChangeTermTo}
                    />
                  </>
                )}
              </div>
            )}

            {SELECTBOX_DISPLAY_CONTROL[reportKey || 1].clt && (
              <div
                style={{
                  marginTop: '20px',
                  textAlign: 'left',
                }}
              >
                <SelectControl
                  label='チェックリスト区分を選択'
                  style={
                    reportKey == 17
                      ? { width: '600px' }
                      : { width: '296px', marginRight: 8 }
                  }
                  value={categKey}
                  options={categOpt}
                  onChange={handleChangeCateg}
                />

                {reportKey !== 17 && (
                  <SelectControl
                    label='チェックリストを選択'
                    style={{ width: '296px' }}
                    value={subCategKey}
                    options={subCategOpt}
                    onChange={handleChangeSubCateg}
                  />
                )}
              </div>
            )}

            {SELECTBOX_DISPLAY_CONTROL[reportKey || 1].dpt && (
              <div style={{ width: '600px' }}>
                <div
                  style={{
                    display:
                      !deptLv2Invalid && dept2Opt.length > 0 ? '' : 'none',
                  }}
                >
                  <SelectControl
                    label='階層レベルⅡの部署を選択'
                    style={{ width: '600px', marginTop: 20 }}
                    value={dept2Key}
                    options={dept2Opt}
                    onChange={handleChangeDepLv2}
                  />
                </div>
                <div
                  style={{
                    display: dept3Opt.length > 0 ? '' : 'none',
                  }}
                >
                  <SelectControl
                    label='階層レベルⅢの部署を選択'
                    style={{ width: '600px', marginTop: 20 }}
                    value={dept3Key}
                    options={dept3Opt}
                    onChange={handleChangeDepLv3}
                  />
                </div>
                <div
                  style={{
                    display: dept4Opt.length > 0 ? '' : 'none',
                  }}
                >
                  <SelectControl
                    label='階層レベルⅣの部署を選択'
                    style={{ width: '600px', marginTop: 20 }}
                    value={dept4Key}
                    options={dept4Opt}
                    onChange={handleChangeDepLv4}
                  />
                </div>
                {reportKey !== 7 && (
                  <div
                    style={{
                      display: dept5Opt.length > 0 ? '' : 'none',
                    }}
                  >
                    <SelectControl
                      label='階層レベルⅤの部署を選択'
                      style={{ width: '600px', marginTop: 20 }}
                      value={dept5Key}
                      options={dept5Opt}
                      onChange={handleChangeDepLv5}
                    />
                  </div>
                )}
              </div>
            )}

            <Button
              variant='contained'
              color='primary'
              onClick={() => handleDownload()}
              style={{
                width: '600px',
                height: '50px',
                marginTop: '15px',
                fontSize: 16,
              }}
            >
              帳票作成
            </Button>
          </div>
        </main>
      }
    />
  );
};

export default ExcelDownload;
