import React from 'react';

import axios from 'axios';

import { Box, Dialog, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ChecklistConfirmContext } from 'views/pages/ChecklistConfirm';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{
  isDialogOpen;
  setIsDialogOpen;
  yearOption;
}> = (props) => {
  const classes = useStyles();

  const { data, setData, orgData, setOrgData, dispData, deleteInfo } =
    React.useContext(ChecklistConfirmContext);

  const { isDialogOpen, setIsDialogOpen } = props;

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = () => {
    axios
      .post('/api/checklist-result/delete', {
        id: deleteInfo.id,
      })
      .then(() => {
        setData(
          data.filter((x) => {
            return x.id != deleteInfo.id;
          })
        );

        setOrgData(
          orgData.filter((x) => {
            return x.id != deleteInfo.id;
          })
        );
      })
      .catch(() => {
        //
      });

    handleClose();
  };

  return (
    <Box component='span' style={{ marginLeft: 8 }}>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName='検査結果削除'
          onClickActionHandler={handleClose}
        />
        <Divider />

        <div style={{ marginTop: 32, marginLeft: 32, marginBottom: 32 }}>
          <div style={{ fontSize: 16, margin: 0 }}>
            検査年度：
            {deleteInfo.inspectionYear}
          </div>
          <div style={{ fontSize: 16, marginTop: 8 }}>
            検査部署：{dispData[0]?.dept_name_lv2}&ensp;
            {dispData[0]?.dept_name_lv3}&ensp;
            {dispData[0]?.dept_name_lv4}&ensp;
            {dispData[0]?.dept_name_lv5}&ensp;
          </div>
          <div style={{ fontSize: 16, marginTop: 8 }}>
            検査対象月：{deleteInfo.inspectionMonth}
          </div>
          <div style={{ fontSize: 16, marginTop: 8 }}>
            チェックリスト名： {deleteInfo.chklstName}
          </div>
          <div style={{ fontSize: 16, marginTop: 8 }}>
            登録日時：{deleteInfo.createdAt}
          </div>
        </div>

        <Divider />

        <DialogActionsDelete
          buttonLabel='検査結果を削除する'
          onClickActionHandler={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
