import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { useAtom } from 'jotai';

import { PAR_PAGE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC<{
  data;
  checkedInfo;
  setCheckedInfo;
}> = (props) => {
  const classes = useStyles();

  const parPage = PAR_PAGE;

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    props.setCheckedInfo({ id: -1 });
    setOffset(offset);
  };

  const handleCheckbox = (
    id: number,
    institution_code: string,
    institution_name: string,
    user_name: string,
    user_dept_name: string,
    user_post: string,
    telephone_number: string,
    email: string,
    account_lock: boolean
  ) => {
    if (id === props.checkedInfo.id) {
      props.setCheckedInfo({
        id: -1,
      });
    } else {
      props.setCheckedInfo({
        id,
        institution_code,
        institution_name,
        user_name,
        user_dept_name,
        user_post,
        telephone_number,
        email,
        account_lock,
      });
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow>
              <StyledTableCell align='center' style={{ width: '10%' }}>
                選択
              </StyledTableCell>
              <StyledTableCell style={{ width: '25%' }}>所属</StyledTableCell>
              <StyledTableCell style={{ width: '25%' }}>氏名</StyledTableCell>
              <StyledTableCell>ログイン ID（メールアドレス）</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.data?.slice(offset, offset + parPage).map((x) => (
              <StyledTableRow key={x.id}>
                <StyledTableCell align='center'>
                  <input
                    type='checkbox'
                    checked={props.checkedInfo.id === x.id}
                    onChange={() => {
                      handleCheckbox(
                        x.id,
                        x.institution_code,
                        x.institution_name,
                        x.user_name,
                        x.user_dept_name,
                        x.user_post,
                        x.telephone_number,
                        x.email,
                        x.account_lock
                      );
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>{x.institution_name}</StyledTableCell>
                <StyledTableCell>{x.user_name}</StyledTableCell>
                <StyledTableCell>{x.email}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props.data.length > PAR_PAGE && (
        <JICSPagination
          length={props.data.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default DataTable;
